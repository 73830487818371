import { IM, IMLayout, SpacingProps, useDimensions, useEvent } from '@infominds/react-native-components'
import { FlashList, FlashListProps, ListRenderItemInfo } from '@shopify/flash-list'
import React, { ForwardedRef, forwardRef, ReactNode } from 'react'
import { Keyboard, NativeScrollEvent, NativeSyntheticEvent, Platform, StyleSheet } from 'react-native'

import { SECTION_LIST_CLOSE_TO_END_EVENT_KEY } from '../constants/EmitterKeys'
import { InfiniteLoadingType } from '../types'
import appUtils from '../utils/appUtils'
import Error from './Error'
import { AnimatedButtonHideEvent } from './Infominds/AnimatedButton'
import RefreshControl from './Infominds/RefreshControl'
import NoEntry from './NoEntry'
import SkeletonCard from './skeleton/SkeletonCard'

type Props<T> = Omit<FlashListProps<T>, 'ref' | 'onRefresh'> & {
  noDataMessage: string
  isSearching?: boolean
  loading?: InfiniteLoadingType
  spacingSkeleton?: SpacingProps
  closeEndThreshold?: number
  hideButtonId?: string
  allDataLoaded?: boolean
  marginTop?: number
  refresh?: () => void
  renderItem: (item: ListRenderItemInfo<T | string>) => ReactNode
  onLoadMore?: () => void
}

function List<T>(
  {
    noDataMessage,
    loading = false,
    isSearching = false,
    marginTop,
    closeEndThreshold,
    hideButtonId,
    spacingSkeleton,
    allDataLoaded,
    refresh,
    onLoadMore,
    ...others
  }: Props<T>,
  ref: ForwardedRef<FlashList<T>>
) {
  // const sbWidth = useScrollbarWidth()
  const { isSmallDevice } = useDimensions()
  const { emit } = useEvent<AnimatedButtonHideEvent>({ key: SECTION_LIST_CLOSE_TO_END_EVENT_KEY })

  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    Platform.OS === 'ios' && Keyboard.isVisible() && Keyboard.dismiss()
    others.onScroll?.(e)

    if (closeEndThreshold === undefined) return

    if (appUtils.closeToEndDetector(e, closeEndThreshold, loading === false && allDataLoaded !== false)) {
      emit({ hide: true, id: hideButtonId ?? '' })
    } else {
      emit({ hide: false, id: hideButtonId ?? '' })
    }
  }

  return (
    <IM.View style={IMLayout.flex.f1}>
      <FlashList
        {...others}
        ref={ref}
        estimatedItemSize={isSmallDevice ? 200 : 100}
        scrollEnabled={loading !== 'reloading'}
        ListHeaderComponent={
          <>
            {loading === 'reloading' && (
              <IM.View spacing="all">
                {Array(20)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <IM.View spacing={spacingSkeleton ?? ['top', 'horizontal']} key={index}>
                        <SkeletonCard />
                      </IM.View>
                    )
                  })}
              </IM.View>
            )}
            <IM.View style={[styles.container, { marginTop: marginTop ?? IMLayout.horizontalMargin }]}>
              <>
                {loading === 'catched' && <Error />}
                {loading === false && others.data?.length === 0 && <>{others.data?.length === 0 && <NoEntry description={noDataMessage} />}</>}
              </>
            </IM.View>
          </>
        }
        ListFooterComponent={
          <IM.View spacing="all">
            {loading === 'loadMore' && !allDataLoaded && (
              <IM.View spacing={spacingSkeleton ?? ['top', 'horizontal']}>
                <SkeletonCard />
              </IM.View>
            )}
          </IM.View>
        }
        refreshControl={!isSearching ? refresh ? <RefreshControl refreshing={false} onRefresh={refresh} /> : undefined : undefined}
        onScroll={handleScroll}
        onEndReachedThreshold={0.5}
        onEndReached={onLoadMore}
      />
    </IM.View>
  )
}

const FlashListData = forwardRef(List) as <T>(props: Props<T> & { ref?: ForwardedRef<FlashList<T>> }) => ReturnType<typeof List>

export default FlashListData

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
})
