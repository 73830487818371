import { IM, IMLayout, useTheme, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import Separator from '../../components/Infominds/Separator'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import ticketUtils from '../../utils/TicketUtils'

type Props<T> = {
  object: T
  enableSeparator?: boolean
}

export default function DetailInfo<
  T extends {
    customer: string
    articleDescription?: string
    serialnumberLocation?: string
    serialnumber?: string
    serialnumberManufactor?: string
    serialnumberManufacturerNumber?: string
    location: string
    shippingAddress?: string
  },
>({ object, enableSeparator, ...props }: Props<T> & ViewProps) {
  const { theme } = useTheme()
  const serial = useMemo(() => ticketUtils.getSerialNumberString(object), [object])

  return (
    <IM.View {...props}>
      {enableSeparator && <Separator />}
      <TextWithIcon iconColor={theme.textDetail} style={IMLayout.flex.f1} icon={['fal', 'building']}>
        {`${object.customer}${object.shippingAddress ? '\n' + object.shippingAddress : ''}\n${object.location}`}
      </TextWithIcon>
      {!!serial && (
        <TextWithIcon iconColor={theme.textDetail} style={IMLayout.flex.f1} icon={['fal', 'barcode']} spacing="top" numberOfLines={3}>
          {`${serial}${object.serialnumberManufactor ? ' (' + object.serialnumberManufactor + ')' : ''}${object.articleDescription ? '\n' + object.articleDescription : ''}`}
        </TextWithIcon>
      )}
      {!!object.serialnumberLocation && (
        <TextWithIcon iconColor={theme.textDetail} style={IMLayout.flex.f1} icon={['fal', 'location-dot']} spacing="top">
          {object.serialnumberLocation}
        </TextWithIcon>
      )}
    </IM.View>
  )
}
