import { useDimensions, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { Platform, SectionListRenderItemInfo, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Contact } from '../../apis/types/apiResponseTypes'
import CustomerContactCard from '../../cards/customer/CustomerContactCard'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import useSearch from '../../components/screen/hooks/useSearch'
import SectionList from '../../components/SectionList'
import { ADD_CONTACT_DESTINATION_BUTTON_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import { EDIT_CONTACT_DATA_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_CUSTOMER_CONTACTS } from '../../constants/Keys'
import ContactEditOrCreateModal from '../../modals/customer/ContactEditOrCreateModal'
import { CustomerStackParamList } from '../../navigation/types'
import { ContactEditOrCreateParams, ContactViewRef, ListSection } from '../../types'

interface Props {
  customerAddressId: string
  show: boolean
  showTitle?: boolean
  skeletonNumber?: number
  hideSectionTitle?: boolean
  hideContactButtons?: boolean
  selectedId?: string
  onPress?: (contact: Contact) => void
  onRefresh?: () => void
}

const ContactsView = (
  { show, customerAddressId, showTitle, selectedId, hideSectionTitle, hideContactButtons, skeletonNumber, onPress, onRefresh }: Props,
  ref: ForwardedRef<ContactViewRef>
) => {
  useImperativeHandle(ref, () => ({
    openModal: () => contactEditOrCreateModalController.show({ addressId: customerAddressId }),
  }))

  const { i18n } = useLanguage()
  const { search } = useSearch()
  const { isSmallDevice } = useDimensions()
  const navigation = useNavigation<NavigationProp<CustomerStackParamList>>()

  const contactEditOrCreateModalController = useModalController<ContactEditOrCreateParams>()

  const {
    item: contacts,
    loadItem: loadContacts,
    loading,
    allDataLoaded,
    loadMore,
  } = useInfiniteLoader(api.getCustomerContacts, { chuckSize: CONSTANTS.defaultChuckSize, id: REQUEST_CUSTOMER_CONTACTS })
  useEvent({ key: EDIT_CONTACT_DATA_EVENT_KEY }, () => refresh())

  useEffect(() => {
    refresh()
  }, [search])

  const refresh = () => {
    onRefresh?.()
    loadContacts({ addressId: customerAddressId, searchtext: search })
  }

  const renderItem = ({ item }: SectionListRenderItemInfo<Contact, ListSection<Contact>>) => {
    return (
      <CustomerContactCard
        contact={item}
        spacing={['horizontal', 'bottom']}
        selected={item.id === selectedId}
        hideButtons={hideContactButtons}
        showGender
        onPress={() => {
          if (onPress) {
            onPress?.(item)
          } else {
            const data: ContactEditOrCreateParams = { contact: item, addressId: customerAddressId }

            isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
              ? navigation.navigate('CustomerDetailContactEditOrCreate', data)
              : contactEditOrCreateModalController.show(data)
          }
        }}
      />
    )
  }

  const data: ListSection<Contact>[] = useMemo(() => {
    const displayData: ListSection<Contact>[] = []

    if (contacts?.length && loading !== 'reloading' && loading !== 'aborted') {
      displayData.push({
        title: showTitle ? i18n.t('CONTACTS') : undefined,
        data: contacts,
      })
    }

    return displayData
  }, [showTitle, contacts, loading])

  return (
    <>
      {show && (
        <SectionList
          contentContainerStyle={stylesList.list}
          loading={loading}
          noDataIcon={['fal', 'user-slash']}
          loadingSection={i18n.t('CONTACTS')}
          noDataSection={i18n.t('CONTACTS')}
          noDataMessage={i18n.t('NO_CONTACT_FOUND')}
          sections={data}
          onRefresh={refresh}
          renderItem={renderItem}
          skeletonElements={skeletonNumber ?? CONSTANTS.accessDataSkeletonCards}
          onLoadMore={loadMore}
          allDataLoaded={allDataLoaded}
          closeEndThreshold={isSmallDevice ? 90 : undefined}
          hideButtonId={ADD_CONTACT_DESTINATION_BUTTON_ID}
          hideSectionTitle={hideSectionTitle}
        />
      )}
      <ContactEditOrCreateModal controller={contactEditOrCreateModalController} />
    </>
  )
}

const stylesList = StyleSheet.create({
  list: { paddingTop: 4 },
})

export default forwardRef(ContactsView)
