import { useContext } from 'react'

import { PlannerContext } from '../components/planner/PlannerContext'

export default function usePlanner() {
  const context = useContext(PlannerContext)

  if (context === undefined) {
    throw new Error('usePlanner() must be called inside PlannerProvider')
  }

  return context
}
