import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IMStyle, useDimensions, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React from 'react'
import { useRecoilState } from 'recoil'

import { ThemeColorExpanded } from '../../types'
import { plannerUtils } from '../../utils/plannerUtils'
import { plannerViewAtom } from '../../utils/stateManager'
import PressableIcon, { PressableIconProps } from '../Infominds/PressableIcon'
import { PlannerView, PlannerViewType } from '../planner/types'
import TabSelector from '../TabSelector'
import useScreen from './hooks/useScreen'
import { LIST_ICON, PLANNER_ICON, PlanningScreenViewType } from './types'

const plannerViews: PlannerViewType[] = ['day', 'week', 'month']

export type PlanningHeaderIconType = { type: PlanningScreenViewType; icon: IconProp; size: number }

type Props = {
  types: PlanningHeaderIconType[]
}

export default function HeaderPlanningChangeViewIcon({ types, ...props }: Props & Omit<PressableIconProps, 'icon'>) {
  const { customerCode } = useAuthentication()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { sessionKey } = useAuthentication()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { planningView, setPlanningView } = useScreen()

  const [plannerView, setPlannerView] = useRecoilState(plannerViewAtom(sessionKey))

  const currentViewIndex = Math.max(
    types.findIndex(t => t.type === planningView),
    0
  )
  const currentView = types.find(t => t.type === planningView) ?? types[0]
  const nextViewIndex = currentViewIndex >= types.length - 1 ? 0 : currentViewIndex + 1

  if (!currentView) return <></>

  const currentPlannerView = plannerViews.findIndex(t => t === plannerView?.view)
  const nextPlannerViewIndex = currentPlannerView >= plannerViews.length - 1 ? 0 : currentPlannerView + 1

  return (
    <>
      <PressableIcon
        color={IMStyle.palette.white}
        icon={plannerUtils.getIcon(plannerViews[nextPlannerViewIndex])}
        size={currentView.size}
        onPress={() => setPlannerView(prev => ({ ...(prev as PlannerView), view: plannerViews[nextPlannerViewIndex] }))}
        hitSlop={{ top: 10, bottom: 8 }}
        {...props}
      />
      {customerCode === '1070' ? (
        <>
          {isSmallDevice ? (
            <PressableIcon
              color={IMStyle.palette.white}
              icon={currentView.icon}
              size={currentView.size}
              onPress={() => setPlanningView(types[nextViewIndex].type)}
              hitSlop={{ top: 10, bottom: 8 }}
              {...props}
            />
          ) : (
            <TabSelector selectionColor={theme.drawerNavigator.bar} height={30} width={360}>
              <TabSelector.Item
                icon={LIST_ICON.icon}
                iconSize={LIST_ICON.size}
                text={i18n.t('LIST')}
                selected={planningView === PlanningScreenViewType.LIST}
                textColor="white"
                selectedTextColor={theme.general.info}
                onPress={() => setPlanningView(PlanningScreenViewType.LIST)}
                backgroundColor={theme.drawerNavigator.bar}
                dividerColor={theme.drawerNavigator.background}
              />
              <TabSelector.Item
                icon={PLANNER_ICON.icon}
                iconSize={PLANNER_ICON.size}
                text={i18n.t('PLANNER')}
                selected={planningView === PlanningScreenViewType.PLANNER}
                onPress={() => setPlanningView(PlanningScreenViewType.PLANNER)}
                textColor="white"
                selectedTextColor={theme.general.info}
                backgroundColor={theme.drawerNavigator.bar}
                dividerColor={theme.drawerNavigator.background}
              />
            </TabSelector>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}
