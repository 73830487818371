/* eslint-disable react-native/no-inline-styles */

import { useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { Button, Popover, Row } from 'antd'
import React, { memo, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilValue } from 'recoil'

import api from '../../apis/apiCalls'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_EMPLOYEE, REQUEST_TEAM } from '../../constants/Keys'
import { ThemeColorExpanded } from '../../types'
import { plannerSelectedEmployeeAtom } from '../../utils/stateManager'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import PlannerFilterIcon from './PlannerFilterIcon'
import PlannerResourceFilterEmployee from './PlannerResourceFilterEmployee'
import PlannerResourceFilterTeam, { NO_TEAM_ID } from './PlannerResourceFilterTeam'

export const resourceFilterStyle: React.CSSProperties = {
  overflowY: 'auto',
  flexWrap: 'nowrap',
  //@ts-ignore ok on web
  maxHeight: '50vh',
  maxWidth: 250,
}

const PlannerResourceFilter = () => {
  const { i18n } = useLanguage()
  const { sessionKey } = useAuthentication()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const { item: teams, loadItem: loadTeams, loading: loadingTeams } = useControlledLoader(api.getTeam, { id: REQUEST_TEAM })
  const { item: employees, loadItem: getEmployee, loading: loadingEmployees } = useControlledLoader(api.getEmployee, { id: REQUEST_EMPLOYEE })

  const selectedEmployees = useRecoilValue(plannerSelectedEmployeeAtom(sessionKey))

  useEffect(() => {
    getEmployee({})
    loadTeams({ readEmployees: true })
  }, [])

  const popoverContent = () => {
    return (
      <Row>
        <PlannerResourceFilterEmployee employees={employees} loadingEmployees={loadingEmployees} />
        <div style={styles.divider} />
        <PlannerResourceFilterTeam
          teams={teams}
          employees={
            employees?.map(el =>
              el.teams.length !== 0
                ? el
                : {
                    ...el,
                    teams: [
                      {
                        id: NO_TEAM_ID,
                        code: NO_TEAM_ID,
                      },
                    ],
                  }
            ) ?? []
          }
          loadingTeams={loadingTeams}
        />
      </Row>
    )
  }

  const colors = CONSTANTS.planner.COLORS[colorScheme]

  return (
    <Popover
      placement="bottomLeft"
      title={i18n.t('EMPLOYEES_FILTER')}
      content={popoverContent}
      trigger="hover"
      color={colorScheme === 'dark' ? theme.header.detail.background : 'white'}>
      <Button type="text" disabled={loadingTeams !== false || loadingEmployees !== false} style={{ height: '26px' }}>
        <PlannerFilterIcon
          count={selectedEmployees ? selectedEmployees.length : 0}
          badgeStyle={{ boxShadow: `0 0 0 1px ${colors.background.planner}` }}
        />
      </Button>
    </Popover>
  )
}

export default memo(PlannerResourceFilter)

const styles = StyleSheet.create({
  divider: { margin: 6 },
})
