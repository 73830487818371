import { IM, IMLayout, IMStyle, useAlert, useEvent, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import groupBy from 'lodash/groupBy'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { SerialNumberCounter } from '../../apis/types/apiResponseTypes'
import Pressable from '../../components/Infominds/Pressable'
import Separator from '../../components/Infominds/Separator'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import DateInput, { convertDateToInputFormat } from '../../components/input/DateInput'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import ArticleCounterReadingSelector from '../../components/selectors/ArticleCounterReadingSelector'
import Text from '../../components/Text'
import { EDIT_ARTICLE_COUNTERS_EVENT_KEY } from '../../constants/EmitterKeys'
import useUserSettings from '../../hooks/useUserSettings'
import { EditOrCreateViewProps, EditOrCreateViewRef, LoadingType, TextInputRef, ThemeColorExpanded, UploadStatus } from '../../types'
import appUtils from '../../utils/appUtils'
import ticketUtils from '../../utils/TicketUtils'
import TimeUtils from '../../utils/TimeUtils'

const styles = StyleSheet.create({
  contract: { marginTop: 2 },
  pressable: { padding: 15 },
  pressableContainer: { margin: -15, marginBottom: 12 },
  title: { justifyContent: 'space-between', alignItems: 'center' },
})

interface Props {
  activityId: string
  focusOnId?: number
  initial: SerialNumberCounter[]
  counters: SerialNumberCounter[]
  view?: 'activity' | 'article'
  emitId?: string
}

const TicketArticleEditCounterView = (
  { activityId, focusOnId, initial, counters, emitId, view = 'article', onUploadStatus }: Props & EditOrCreateViewProps,
  ref: ForwardedRef<EditOrCreateViewRef>
) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const alert = useAlert()
  const { i18n, language } = useLanguage()
  const { userSettings } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()

  const errorMessage = useRef<unknown>('')
  const textInputArrayRef = useRef<(TextInputRef | null)[]>([])

  const initialData = useRef(counters.filter(el => !el.calculationFormula))
  const [state, setState] = useState(counters.filter(el => !el.calculationFormula))
  const [shownId, setShownId] = useState<string | undefined>(undefined)
  const [uploadStatus, setUploadStatus] = useState<LoadingType>('init')
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')

  const refresh = useEvent<string | undefined>({ key: EDIT_ARTICLE_COUNTERS_EVENT_KEY })
  const mandatory = view === 'activity' && userSettings && userSettings.mandatoryInputOfTheValueDuringCounterReading

  useEffect(() => {
    if (checkEquality(initialData.current, state)) {
      updateUploadStatus('done')
    } else {
      if (mandatory && checkMandatoryMissing(state)) {
        updateUploadStatus('mandatoryMissing')
      } else {
        updateUploadStatus('waiting')
      }
    }
  }, [mandatory, state])

  useEffect(() => {
    if (uploadStatus === 'init') return

    if (uploadStatus === 'catched') {
      alert.alert(i18n.t('API_CATCH_TITLE'), appUtils.getBackendErrorMessage(errorMessage.current))
      updateUploadStatus('waiting')
      return
    }

    updateUploadStatus('done')
    refresh.emit(emitId)
  }, [uploadStatus])

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const upload = () => {
    updateUploadStatus('uploading')

    const promises: Promise<string>[] = []

    prepareObject(state).forEach(value => {
      promises.push(api.editSerialNumberCounter({ ...value, movementActivityId: activityId }))
    })

    Promise.all(promises)
      .then(() => {
        setUploadStatus(false)
      })
      .catch(err => {
        setUploadStatus('catched')
        errorMessage.current = err
      })
  }

  const checkEquality = (reference: SerialNumberCounter[], current: SerialNumberCounter[]) => {
    const toRet = new Array<boolean>(reference.length).fill(false)

    reference.forEach((refEl, index) => {
      current.forEach(currEl => {
        if (currEl.id === refEl.id) {
          const initialCounter = initial.find(el => el.id === currEl.id)

          if (initialCounter) {
            if (initialCounter.movementValue !== undefined && initialCounter.movementValue === currEl.movementValue) {
              toRet[index] = currEl.movementNote === initialCounter.movementNote
            } else {
              if (currEl.movementRadingType === 'StartCounter') {
                toRet[index] = currEl.movementValue === undefined
              } else {
                if (view === 'activity') {
                  toRet[index] = currEl.movementValue ? (initialCounter.movementValue ?? 0) >= currEl.movementValue : true
                } else {
                  toRet[index] = currEl.movementValue ? (initialCounter.movementValue ?? 0) > currEl.movementValue : true
                }
              }
            }
          }
        }
      })
    })

    return !toRet.some(el => !el)
  }

  const checkMandatoryMissing = (a: SerialNumberCounter[]) => {
    let toRet = false

    a.forEach(aEl => {
      if (aEl.movementValue === undefined) {
        toRet = true
      }
    })

    return toRet
  }

  const handleChangeText = (id: string, newVal: Partial<SerialNumberCounter>) => {
    if (waitingUpload === 'uploading') return

    // @ts-ignore not important
    setState(prev => {
      const toRet = prev.map(el => {
        if (el.id === id) {
          return { ...el, ...newVal }
        } else {
          return el
        }
      })

      return toRet
    })
  }

  const prepareObject = (obj: SerialNumberCounter[]) => {
    return obj.filter(el => el.movementValue !== undefined)
  }

  const errorCounter = (counter: SerialNumberCounter, initialCounter: SerialNumberCounter) => {
    if (counter.movementRadingType === 'StartCounter') return false

    return view === 'activity'
      ? counter.movementValue === undefined || counter.movementValue < (initialCounter.movementValue ?? 0)
      : counter.movementValue === undefined
        ? false
        : counter.movementValue < (initialCounter.movementValue ?? 0)
  }

  const errorCounters = (counterArray: SerialNumberCounter[]) => {
    let error = false

    initial.forEach(refEl => {
      counterArray.forEach(currEl => {
        if (currEl.id === refEl.id) {
          const initialCounter = initial.find(el => el.id === refEl.id)

          if (initialCounter && !error) {
            error = errorCounter(currEl, initialCounter)
          }
        }
      })
    })

    return error
  }

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
    editable: waitingUpload !== 'uploading',
  }

  const data = useMemo(() => {
    const toRet: { title: string; counters: SerialNumberCounter[] }[] = []

    const groups = groupBy(state, el => el.serialnumberId)

    Object.keys(groups).forEach(key => {
      const firstValue = groups[key][0]

      toRet.push({
        title: ticketUtils.getArticleSn(firstValue) + (firstValue.serialnumberLocation ? `\n${firstValue.serialnumberLocation}` : ''),
        counters: groups[key].sort((a, b) => a.position - b.position),
      })
    })

    return toRet
  }, [state])

  return (
    <ScrollViewForm>
      {data.map((elem, idx) => {
        const shown = shownId === elem.counters[0].id

        return (
          <Pressable
            key={idx}
            style={styles.pressable}
            containerStyle={styles.pressableContainer}
            onPress={() => setShownId(shown ? undefined : elem.counters[0].id)}
            disabled={view === 'article' || data.length === 1}>
            {view === 'activity' && (
              <IM.View style={[IMLayout.flex.row, styles.title]}>
                <Text style={{ fontSize: IMStyle.typography.fontSizeRegular, fontWeight: IMStyle.typography.fontWeightMedium }}>{elem.title}</Text>
                {data.length !== 1 && (
                  <IM.View style={IMLayout.flex.row}>
                    {errorCounters(elem.counters) && (
                      <IM.Icon
                        icon={['fas', 'triangle-exclamation']}
                        color={theme.general.error}
                        style={{ marginLeft: IMLayout.horizontalMargin }}
                        size={18}
                      />
                    )}
                    <IM.Icon icon={['fal', shown ? 'chevron-up' : 'chevron-down']} style={{ marginLeft: 3 * IMLayout.horizontalMargin }} size={18} />
                  </IM.View>
                )}
              </IM.View>
            )}

            {(view === 'article' || shown || data.length === 1) &&
              elem.counters?.map((counter, index) => {
                const initialCounter = initial.find(el => el.id === counter.id)
                const isFirst = index === 0
                const isLast = index === counters.length - 1

                if (!initialCounter) return

                return (
                  <IM.View key={counter.id}>
                    {!isFirst && <Separator spacing="none" />}
                    <IM.View spacing={isFirst ? 'none' : 'top'}>
                      <IM.View spacing="top">
                        <Text style={{ fontWeight: IMStyle.typography.fontWeightBold, fontSize: IMStyle.typography.fontSizeRegular }}>
                          {counter.counterType}
                        </Text>
                        <TextWithIcon
                          alignIcon="right"
                          icon={['fas', counter.contract ? 'circle-check' : 'circle-xmark']}
                          color={counter.contract ? theme.general.info : theme.general.error}
                          style={styles.contract}>
                          <IM.Text>{i18n.t('CONTRACT')}</IM.Text>
                        </TextWithIcon>
                      </IM.View>
                      <DateInput
                        title={i18n.t('LAST_MONITORING')}
                        value={counter.movementDate ? convertDateToInputFormat(new Date(counter.movementDate), language) : undefined}
                        details={Utils.stringValueReplacer(
                          i18n.t('COUNTER_LAST_READ'),
                          initialCounter.movementDate ? TimeUtils.format(initialCounter.movementDate, language) : '-'
                        )}
                        minimumDate={initialCounter.movementDate ? new Date(initialCounter.movementDate) : new Date()}
                        maximumDate={new Date()}
                        onChangeDate={date => {
                          if (!date || date === new Date()) return

                          handleChangeText(counter.id, { movementDate: date.toISOString() })
                        }}
                        {...commonProps}
                      />
                      <ArticleCounterReadingSelector
                        value={{ id: counter.movementRadingType, value: ticketUtils.getCounterReadingTranslation(counter.movementRadingType, i18n) }}
                        onChange={value => handleChangeText(counter.id, { movementRadingType: value ?? 'None' })}
                        {...commonProps}
                        editable={waitingUpload !== 'uploading' && userSettings?.allowChangeCounterType}
                      />
                      <TextInput
                        ref={innerRef => {
                          if (textInputArrayRef.current.length < index) {
                            textInputArrayRef.current?.push(innerRef)
                          } else {
                            textInputArrayRef.current[index] = innerRef
                          }
                        }}
                        value={counter.movementValue ? counter.movementValue.toString() : undefined}
                        title={i18n.t('COUNTER_VALUE') + (mandatory ? ' *' : '')}
                        details={
                          Utils.stringValueReplacer(i18n.t('COUNTER_LAST_VALUE'), initialCounter.movementValue ?? '-') +
                          (counter.calculationFormula ? `\n${i18n.t('FORMULA')}: ${counter.calculationFormula}` : '')
                        }
                        type="number"
                        autoFocus={Platform.OS === 'android' && focusOnId === index}
                        onChangeText={text => handleChangeText(counter.id, { movementValue: text === '' ? undefined : parseInt(text, 10) })}
                        onSubmitEditing={() => {
                          if (!isLast) textInputArrayRef.current[index + 1]?.focus()
                        }}
                        // Next is not shown in iOS https://github.com/facebook/react-native/issues/31794
                        returnKeyType={Platform.OS === 'ios' ? undefined : isLast ? 'done' : 'next'}
                        blurOnSubmit={isLast}
                        error={errorCounter(counter, initialCounter)}
                        {...commonProps}
                      />
                      <TextInput
                        title={i18n.t('COMMENT')}
                        value={counter.movementNote}
                        multiline
                        fixMultilineHeight
                        onChangeText={text => handleChangeText(counter.id, { movementNote: text === '' ? undefined : text })}
                        {...commonProps}
                      />
                    </IM.View>
                  </IM.View>
                )
              })}
          </Pressable>
        )
      })}
    </ScrollViewForm>
  )
}

export default forwardRef(TicketArticleEditCounterView)
