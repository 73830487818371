import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome'
import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { Pressable, PressableProps, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../types'
import NotificationBadge from '../NotificationBadge'

type Props = {
  icon: IconProp
  size?: number
  color?: string
  shadow?: boolean
  style?: StyleProp<ViewStyle>
  pressableRatio?: number
  pressableStyle?: StyleProp<ViewStyle>
  opacityColor?: string
  disabledColor?: string
  iconStyle?: FontAwesomeIconStyle
  badgeLoading?: boolean
  badgeNumber?: number
  badgeColor?: string
}

export type PressableIconProps = PressableProps & Props

const PressableIcon = memo(function PressableIcon({
  icon,
  size = 30,
  color,
  shadow,
  style,
  pressableStyle,
  pressableRatio = 0.5,
  iconStyle,
  opacityColor,
  disabledColor,
  badgeLoading,
  badgeNumber,
  badgeColor,
  ...pressableProps
}: PressableIconProps) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View style={[shadow && IMLayout.shadow, style]}>
      <Pressable
        hitSlop={pressableProps.hitSlop}
        style={({ pressed }) => [
          styles.pressable,
          {
            padding: 18 - size * pressableRatio,
            backgroundColor: pressed && !pressableProps.android_ripple ? (opacityColor ?? theme.button.pressedOpacity) : 'transparent',
          },
          pressableStyle,
        ]}
        {...pressableProps}>
        <IM.View>
          <IM.Icon
            size={size}
            icon={icon}
            color={pressableProps.disabled ? (disabledColor ?? theme.pressableIcon.disabled) : (color ?? theme.text)}
            iconStyle={iconStyle}
          />
          {badgeNumber !== undefined && (
            <NotificationBadge
              number={badgeNumber}
              color={badgeColor}
              disabled={pressableProps.disabled}
              disabledColor={disabledColor}
              loading={badgeLoading}
              style={styles.badge}
            />
          )}
        </IM.View>
      </Pressable>
    </IM.View>
  )
})

export default PressableIcon

const styles = StyleSheet.create({
  badge: { top: -11, right: -12 },
  pressable: {
    borderRadius: IMLayout.iconRadius,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
