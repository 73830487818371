import { useLanguage } from '@infominds/react-native-components'
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'
import { Keyboard } from 'react-native'

import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ActivityStackScreenProps } from '../../navigation/types'
import { ActivityArticleCounterParams, EditOrCreateViewRef, ModalScreenProps, ModalScreenRef } from '../../types'
import TicketArticleEditCounterView from '../../views/ticket/TicketArticleEditCounterView'

const ActivityArticleCounterScreen = (
  { route, controller }: Pick<ActivityStackScreenProps<'ActivityArticleCounter'>, 'route'> & ModalScreenProps<ActivityArticleCounterParams>,
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const editOrCreateViewRef = createRef<EditOrCreateViewRef>()

  const { i18n } = useLanguage()
  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined

  const data = useMemo(() => {
    return cloneDeep(route.params.counters).map(el => {
      if (el.movementDate) {
        const counterDate = dayjs(el.movementDate).startOf('day')
        const todayDate = dayjs().startOf('day')

        if (counterDate.isBefore(todayDate)) {
          el.movementValue = undefined
          el.movementDate = new Date().toISOString()
        }
      } else {
        el.movementDate = new Date().toISOString()
      }

      return el
    })
  }, [route])

  return (
    <Screen transparent={isModal}>
      <Screen.Header
        goBack={handleGoBack}
        backHandlerCallback={handleGoBack}
        isModal={isModal}
        title={i18n.t('COUNTERS_READING')}
        onRightIconPress={() => {
          Keyboard.dismiss()
          editOrCreateViewRef.current?.handleUpload()
        }}
        rightIcon={['fal', 'check']}
        disable={status === 'done' || status === 'mandatoryMissing'}
        loading={status === 'uploading'}
      />
      <Screen.Content>
        <FormProvider>
          <TicketArticleEditCounterView
            ref={editOrCreateViewRef}
            view="activity"
            initial={route.params.counters}
            counters={data}
            onUploadStatus={setStatus}
            emitId={route.params.emitId}
            activityId={route.params.activityId}
          />
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(ActivityArticleCounterScreen)
