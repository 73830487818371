import { Dayjs } from 'dayjs'
import { ReactNode } from 'react'
import { DnDSource, EventItem, Resource } from 'react-big-scheduler-stch'
import { SetterOrUpdater } from 'recoil'

import { ActivityType } from '../../types'

export type PlannerProps = {
  dndSources: DnDSource[]
  style?: React.CSSProperties
  onRefresh: (date: Date) => void
  onMove: (event: EventItem, slotId: string, slotName: string, start: string, end: string) => void
  onMoveStart: (event: EventItem, start: string) => void
  onMoveEnd: (event: EventItem, end: string) => void
  onDateChange: SetterOrUpdater<PlannerView | undefined>
  renderResourceHeader?: ((resourceName: string) => React.ReactNode) | undefined
  renderItem: (event: EventItem, bgColor: string, isStart: boolean, isEnd: boolean, mustAddCssClass: string, mustBeHeight: number) => ReactNode
  renderItemPopover: (eventItem: EventItem, title: string, start: Dayjs, end: Dayjs, statusColor: string) => ReactNode
}

export interface PlannerRef {
  next: () => void
  previous: () => void
  pick: (date: Date) => void
  remove: (event: EventItem | EventItem[]) => void
  add: (event: EventItem | EventItem[]) => void
  updateResources: (currentResource: Resource[]) => void
}

export type PlannerViewType = 'day' | 'week' | 'month'

export type PlannerTheme = {
  background: { planner: string; popover: string }
  border: { primary: string; secondary: string }
  nonWorking: { background: string; text: string }
  selection: string
  text: string
}

export type PlannerView = {
  label: string
  date: string
  view: PlannerViewType
}

export type EventTitle = {
  ticketCode: string
  activityCode: string
  activityType: ActivityType
}

export enum DnDType {
  ACTIVITY = 'Activity',
  TRAVEL_TIME = 'TravelTime',
}
