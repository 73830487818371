import { IM, IMLayout, SpacingProps, useLanguage, Utils } from '@infominds/react-native-components'
import React from 'react'

import { QualityCharacteristicCheck, QualityCheckType } from '../types'
import Text from './Text'

interface Props {
  type: QualityCheckType
  check: QualityCharacteristicCheck | undefined
  spacing?: SpacingProps
}

export default function QualityTarget({ type, check, spacing }: Props) {
  const { i18n } = useLanguage()

  const render = () => {
    switch (type) {
      case 'Text': {
        return <Text>{check?.targetText}</Text>
      }
      case 'Numeric': {
        if (check?.targetNumericFrom && check?.targetNumericUntil) {
          return (
            <Text>
              {check?.targetNumericFrom} - {check?.targetNumericUntil}
            </Text>
          )
        } else if (check?.targetNumericFrom && check?.targetNumericUntil === undefined) {
          return <Text>{Utils.stringValueReplacer(i18n.t('GREATER_THAN_EQUAL_TO'), check?.targetNumericFrom)}</Text>
        }
        return (
          <Text>
            {check?.targetNumericFrom} - {check?.targetNumericUntil}
          </Text>
        )
      }
      case 'Boolean': {
        return <Text>{check?.targetBoolean ? i18n.t('TRUE').toLocaleLowerCase() : i18n.t('FALSE').toLocaleLowerCase()}</Text>
      }
      case 'Selection': {
        return <Text>{check?.targetText}</Text>
      }
      case 'None': {
        return <></>
      }
    }
  }
  return (
    <>
      {check && (
        <IM.View spacing={spacing}>
          <IM.View style={IMLayout.flex.row}>
            {((type === 'Text' && check.targetText !== undefined) ||
              (type === 'Selection' && check.targetSelectionDetailId !== undefined) ||
              (type !== 'Text' && type !== 'Selection')) && (
              <>
                <Text secondary>{i18n.t('SHOULD_BE')}</Text>
                <IM.View spacing="left">{render()}</IM.View>
              </>
            )}
          </IM.View>
        </IM.View>
      )}
    </>
  )
}
