import { IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { CheckboxOptionType, Col, Row } from 'antd'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilState } from 'recoil'

import { Employee, Team } from '../../apis/types/apiResponseTypes'
import { LoadingType, ThemeColorExpanded } from '../../types'
import { plannerSelectedEmployeeAtom } from '../../utils/stateManager'
import Text from '../Text'
import PlannerCheckboxGroup from './PlannerCheckboxGroup'

export const NO_TEAM_ID = '-1'

type Props = {
  employees: Employee[]
  teams: Team[] | undefined
  loadingTeams: LoadingType
}

const PlannerResourceFilterTeam = ({ loadingTeams, employees, teams }: Props) => {
  const { i18n } = useLanguage()
  const { sessionKey } = useAuthentication()
  const { theme } = useTheme<ThemeColorExpanded>()

  const [selectedTeams, setSelectedTeams] = useState<{ [key: string]: number } | undefined>()
  const [selectedEmployeesIds, setSelectedEmployeesIds] = useRecoilState(plannerSelectedEmployeeAtom(sessionKey))

  useEffect(() => {
    if (selectedEmployeesIds === undefined || employees === undefined) return

    setSelectedTeams(getTeamIds(employees, selectedEmployeesIds))
  }, [selectedEmployeesIds])

  const handleTeamsChange = (teamsToUpd: string[]) => {
    console.log('🚀 ~ handleTeamsChange ~ teamsToUpd:', teamsToUpd)
    const keyToRemove = Object.keys(selectedTeams ?? {}).filter(key => !teamsToUpd.includes(key))
    const keyToAdd = teamsToUpd.filter(key => !Object.keys(selectedTeams ?? {}).includes(key))
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    console.log(`🚀 ~ selected ${JSON.stringify(selectedTeams)} - keyToRemove ${keyToRemove} - keyToAdd ${keyToAdd}:`)

    if (keyToRemove.length > 0) {
      const actualEmployees: Employee[] = []

      employees.forEach(employee => {
        let toInclude = true

        employee.teams.forEach(team => {
          if (!teamsToUpd.includes(team.id)) {
            toInclude = false
          }
        })

        if (toInclude) {
          actualEmployees.push(employee)
        }
      })

      setSelectedEmployeesIds(actualEmployees.map(el => el.id))
    }
    if (keyToAdd.length > 0) {
      const actualEmployees: Employee[] = []

      employees.forEach(employee => {
        let toInclude = false

        employee.teams.forEach(team => {
          if (teamsToUpd.includes(team.id)) {
            toInclude = true
          }
        })

        console.log('🚀 ~ handleTeamsChange ~ employee.teams:', JSON.stringify(employee.teams), toInclude)
        if (toInclude) {
          actualEmployees.push(employee)
        }
      })

      setSelectedEmployeesIds(actualEmployees.map(el => el.id))
    }
  }

  const teamOptions: CheckboxOptionType<string>[] = useMemo(() => {
    return (teams ?? []).map(team => {
      const selectedEmployeesTeams = getTeamIds(employees, selectedEmployeesIds)
      const selectedEmployeesNumber = selectedEmployeesTeams ? selectedEmployeesTeams[team.id] : undefined

      return {
        id: team.id,
        label: team.description,
        value: team.id,
        indeterminate: selectedEmployeesNumber !== undefined ? selectedEmployeesNumber !== team.employees?.length : false,
        style: { color: theme.text },
      }
    })
  }, [selectedEmployeesIds])

  return (
    <Col>
      <Row style={styles.subtitle}>
        <Text style={styles.subtitleText}>{i18n.t('TEAMS')}</Text>
      </Row>
      <PlannerCheckboxGroup
        options={[
          ...teamOptions,
          { label: i18n.t('WITHOUT_TEAM'), value: NO_TEAM_ID, id: NO_TEAM_ID, style: { color: theme.text, fontStyle: 'italic' } },
        ]}
        selected={selectedTeams ? Object.keys(selectedTeams) : []}
        setSelected={handleTeamsChange}
      />
    </Col>
  )
}

export default memo(PlannerResourceFilterTeam)

const styles = StyleSheet.create({
  subtitle: { paddingBottom: 4 },
  subtitleText: { fontWeight: IMStyle.typography.fontWeightMedium },
})

const getTeamIds = (employees: Employee[], selectedEmployeesIds?: string[]): { [key: string]: number } => {
  const teamsId: string[] = []

  const selectedEmployees = getSelectedEmployees(employees, selectedEmployeesIds)

  selectedEmployees.forEach(employee => {
    employee?.teams.forEach(employeeTeam => teamsId.push(employeeTeam.id))
  })

  return teamsId.reduce((obj, b) => {
    //@ts-ignore: ok
    obj[b] = ++obj[b] || 1
    return obj
  }, {})
}

const getSelectedEmployees = (employees: Employee[], selectedEmployeesIds?: string[]) =>
  employees
    .map(employee => {
      if (selectedEmployeesIds === undefined) return employee

      return selectedEmployeesIds.find(employeeId => employeeId === employee.id) !== undefined ? employee : undefined
    })
    .filter(el => el !== undefined)
