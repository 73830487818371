import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'

import api from '../../apis/apiCalls'
import { Contact, Ticket } from '../../apis/types/apiResponseTypes'
import useRequest from '../../components/Infominds/hooks/useRequest'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_POST_TICKET_DETAIL } from '../../constants/Keys'
import { Gender } from '../../types'
import CustomerContactIdCard, { CustomerContactIdCardProps } from '../customer/CustomerContactIdCard'

interface Props {
  ticketId: string
  onChangedContactId: (contactId: string | undefined) => void
}

export default function TicketDetailCustomerContactIdCard({ ticketId, onChangedContactId, ...others }: CustomerContactIdCardProps & Props) {
  const contactId = useRef(others.contactId)

  const { i18n } = useLanguage()
  const [error, setError] = useState(false)

  const { request, loading: loadingEdit } = useRequest(api.editTicket, { id: REQUEST_POST_TICKET_DETAIL })

  useEffect(() => {
    loadingEdit === 'catched' && setError(true)
    loadingEdit === false && onChangedContactId(contactId.current)
  }, [loadingEdit])

  const handleChangeContact = (newContact: Contact | undefined) => {
    if (!newContact) return

    contactId.current = newContact.id === noSelectionEntry.id ? undefined : newContact.id

    setError(false)
    request({
      id: ticketId,
      contactId: newContact.id === noSelectionEntry.id ? '' : newContact.id,
    } as Ticket)
  }

  const noSelectionEntry: Contact = {
    id: CONSTANTS.noSelectionId,
    addressId: '',
    companyId: '',
    gender: Gender.None,
    inactive: false,
    description: i18n.t('NO_SELECTION'),
  }

  return (
    <CustomerContactIdCard loading={loadingEdit} error={error} noElement={noSelectionEntry} onChange={handleChangeContact} showGender {...others} />
  )
}
