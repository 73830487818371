import { IM, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import Clipboard from '@react-native-clipboard/clipboard'
import React, { useEffect, useState } from 'react'
import { Platform, Pressable, StyleSheet } from 'react-native'

import { getApi } from '../apis/apiCalls'
import { AccessData } from '../apis/types/apiResponseTypes'
import { accessDataCardStyle } from '../cards/accessData/AccessDataCard'
import CONSTANTS from '../constants/Constants'
import { ACCESS_DATA_CARD_SELECTION_EVENT_KEY } from '../constants/EmitterKeys'
import { REQUEST_PASSWORD } from '../constants/Keys'
import { useDataProvider } from '../dataProvider/hooks/useDataProvider'
import useVault from '../hooks/useVault'
import VaultModal from '../modals/VaultModal'
import { ThemeColorExpanded } from '../types'
import useControlledLoader from './Infominds/hooks/useControlledLoader'
import PressableIcon from './Infominds/PressableIcon'
import Otp from './Otp'

interface Props {
  open: boolean
  accessGranted: boolean
  data: AccessData
}

export default function Password({ open, accessGranted, data }: Props) {
  const { i18n } = useLanguage()
  const { sessionId } = useVault()
  const { isOnline } = useDataProvider()
  const modalController = useModalController()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { client } = useDataProvider()

  const [passwordShown, setPasswordShown] = useState(false)
  const [passwordCopied, setPasswordCopied] = useState(false)
  const [loadingIcon, setLoadingIcon] = useState<'eye' | 'copy'>('eye')

  useEvent({ key: ACCESS_DATA_CARD_SELECTION_EVENT_KEY }, () => setPasswordShown(false))

  const {
    item: response,
    loadItem: downloadPassword,
    loading,
    setItem: setPassword,
  } = useControlledLoader(getApi(client).getPassword, {
    id: REQUEST_PASSWORD,
    onResult: passResponse => {
      if (passResponse.vaultState === 'SessionClosed') {
        modalController.show()
        return 'vault-locked'
      } else {
        return 'vault-unlocked'
      }
    },
  })

  const password = response?.data

  useEffect(() => {
    !open && passwordShown && setPasswordShown(false)
  }, [open])

  useEffect(() => {
    passwordCopied && setTimeout(() => setPasswordCopied(false), CONSTANTS.resetCheckIcon)
  }, [passwordCopied])

  useEffect(() => {
    if (password && loading === false && loadingIcon === 'copy') {
      setPasswordCopied(true)
      Clipboard.setString(password)
    }
  }, [loading])

  const handleShowPress = () => {
    setLoadingIcon('eye')

    if (passwordShown) {
      setPasswordShown(false)
    } else {
      download(sessionId)
      setPasswordShown(true)
    }
  }

  const handleCopyPress = () => {
    if (passwordCopied) return

    if (passwordShown) {
      if (password) {
        setPasswordCopied(true)
        Clipboard.setString(password)
      }
    } else {
      setLoadingIcon('copy')
      download(sessionId)
    }
  }

  const download = (id: string) => {
    setPassword(undefined)
    downloadPassword({ VaultSessionId: id, id: data.id })
  }

  const disablePasswordCopy = !data.passwordAvailable || !accessGranted
  const disabledPasswordReveal = (isOnline === false && passwordShown === false) || disablePasswordCopy
  const passwordCopyPlaceholder = passwordCopied && Platform.OS !== 'android'

  return (
    <>
      <IM.View style={styles.flex}>
        <IM.View style={styles.row}>
          <Pressable style={styles.flex} disabled={disablePasswordCopy} onPress={handleCopyPress} hitSlop={{ right: -5 }}>
            <IM.Text style={accessDataCardStyle.title}>{i18n.t('PASSWORD')}</IM.Text>
            <IM.Text secondary={!data.passwordAvailable || passwordCopyPlaceholder}>
              {data.passwordAvailable
                ? passwordCopyPlaceholder
                  ? i18n.t('COPIED_TO_CLIPBOARD')
                  : password === undefined || passwordShown === false
                    ? '*************'
                    : password
                : i18n.t('NO_PASSWORD_AVAILABLE')}
            </IM.Text>
          </Pressable>
          <IM.View style={styles.row}>
            <IM.View style={styles.icon}>
              {loading === 'reloading' && loadingIcon === 'eye' ? (
                <IM.LoadingSpinner isVisible size={20} style={styles.spinner} />
              ) : (
                <PressableIcon
                  icon={['fal', passwordShown ? 'eye-slash' : 'eye']}
                  hitSlop={{ bottom: 6, top: 6, left: 6, right: 0 }}
                  size={16}
                  disabled={disabledPasswordReveal}
                  onPress={handleShowPress}
                />
              )}
            </IM.View>
            <IM.View style={styles.icon}>
              {loading === 'reloading' && loadingIcon === 'copy' ? (
                <IM.LoadingSpinner isVisible size={20} style={styles.spinner} />
              ) : (
                <>
                  {passwordCopied ? (
                    <PressableIcon
                      icon={['fal', 'check']}
                      hitSlop={{ bottom: 6, top: 6, left: 2, right: 6 }}
                      color={theme.general.info}
                      size={18}
                      opacityColor={theme.card.background}
                    />
                  ) : (
                    <PressableIcon
                      icon={['fal', 'copy']}
                      hitSlop={{ bottom: 6, top: 6, left: 2, right: 6 }}
                      size={16}
                      disabled={disablePasswordCopy}
                      onPress={handleCopyPress}
                    />
                  )}
                </>
              )}
            </IM.View>
          </IM.View>
        </IM.View>
        {data.otpActive && open && <Otp data={data} accessGranted={accessGranted} />}
      </IM.View>
      <VaultModal controller={modalController} onAccept={download} />
    </>
  )
}

const styles = StyleSheet.create({
  icon: {
    paddingRight: 2,
  },
  row: {
    flexDirection: 'row',
  },
  flex: {
    flex: 1,
  },
  spinner: { marginVertical: 7, marginHorizontal: 8, width: 20 },
})
