import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { ListSection, ReadingType, SelectorReadingType } from '../../types'
import appUtils from '../../utils/appUtils'
import ticketUtils from '../../utils/TicketUtils'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  value: SelectorReadingType
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: ReadingType | undefined) => void
}

export default function ArticleCounterReadingSelector({ value, onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const data = useMemo(() => ticketUtils.createCounterReading(i18n), [])

  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState(data.find(el => el.id === 'Activity') ?? value)

  useEffect(() => {
    onChange(selected.id)
  }, [])

  const render = ({ item }: SectionListRenderItemInfo<SelectorReadingType, ListSection<SelectorReadingType>>, onPress?: () => void) => {
    return <RadioPressable onPress={() => onPress?.()} selected={item.id === selected.id} text={item.value} />
  }

  const handleOnChange = (newValue: SelectorReadingType | undefined) => {
    if (!newValue) return

    setSelected(newValue)
    onChange(newValue.id)
  }

  return (
    <SelectInput
      data={appUtils.filter(data ? data : [], search, ['value'])}
      value={data?.find(elem => elem.id === selected.id)}
      loading={false}
      refresh={() => {
        return
      }}
      title={i18n.t('READING_TYPE')}
      screenTitle={i18n.t('READING_TYPES')}
      noDataMessage={i18n.t('NO_READING_TYPE_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.value}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableReset
      disableLoadAfterMount
      {...props}
    />
  )
}
