import { useAlert, useEvent, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Animated } from 'react-native'

import { getApi } from '../../apis/apiCalls'
import useRequest from '../../components/Infominds/hooks/useRequest'
import SerialNumberHeader from '../../components/screen/headers/SerialNumberHeader'
import { Screen } from '../../components/screen/Screen'
import { REFRESH_TICKET_DETAIL_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_DELETE_TICKET_ARTICLE } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import { TicketStackScreenProps } from '../../navigation/types'
import TicketArticleView from '../../views/ticket/TicketArticleView'

export default function TicketArticleScreen({ route, navigation }: TicketStackScreenProps<'TicketArticle'>) {
  const buttonAnimationValue = new Animated.Value(0)

  const alert = useAlert()
  const { i18n } = useLanguage()
  const { emit } = useEvent({ key: REFRESH_TICKET_DETAIL_EVENT_KEY })
  const { client } = useDataProvider()
  const { request, loading } = useRequest(getApi(client).deleteTicketArticle, { id: REQUEST_DELETE_TICKET_ARTICLE })

  const [deletePressed, setDeletePressed] = useState(false)

  useEffect(() => {
    if (loading === 'reloading') {
      setDeletePressed(true)
    } else if (deletePressed && loading === false) {
      emit()
      setDeletePressed(false)
      navigation.goBack()
    }
  }, [deletePressed, loading])

  return (
    <Screen>
      <Screen.Header goBack>
        <SerialNumberHeader
          title={i18n.t('SERIAL_NUMBER')}
          description={
            route.params.serialNumber
              ? route.params.serialNumber + (route.params.serialNumberManufactor ? ` (${route.params.serialNumberManufactor})` : '')
              : undefined
          }
          loading={loading}
          onPress={() => {
            alert.alert(i18n.t('REMOVE_FROM_TICKET'), i18n.t('REMOVE_FROM_TICKET_MESSAGE'), [
              {
                text: i18n.t('REMOVE'),
                onPress: () => request({ id: route.params.articleId }),
                style: 'destructive',
              },
              {
                text: i18n.t('CANCEL'),
                style: 'cancel',
              },
            ])
          }}
        />
      </Screen.Header>
      <Screen.Content>
        <TicketArticleView
          ticketId={route.params.ticketId}
          articleId={route.params.articleId}
          activityId={route.params.activityId}
          buttonAnimationValue={buttonAnimationValue}
        />
      </Screen.Content>
    </Screen>
  )
}
