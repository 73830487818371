import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, SpacingProps, TextProps, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

export type TextWithIconProps = {
  icon?: IconProp
  alignIcon?: 'left' | 'right'
  viewStyle?: StyleProp<ViewStyle>
  iconStyle?: StyleProp<ViewStyle>
  iconSize?: number
  iconColor?: string
  color?: string
  spacing?: SpacingProps
} & TextProps

const TextWithIcon = memo(function TextWithIcon({
  icon,
  viewStyle,
  iconStyle,
  iconColor,
  alignIcon = 'left',
  iconSize = 15,
  style,
  color,
  spacing,
  ...textProps
}: TextWithIconProps) {
  const { theme } = useTheme()
  let col = textProps.primary ? undefined : color
  col = textProps.secondary ? undefined : color

  return (
    <IM.View style={[styles.container, viewStyle]} spacing={spacing}>
      {icon && alignIcon === 'left' && (
        <IM.Icon
          color={textProps.secondary || textProps.disabled ? theme.textDetail : (color ?? iconColor ?? theme.text)}
          style={[styles.icon, styles.iconLeft, iconStyle]}
          icon={icon}
          size={iconSize}
        />
      )}
      <IM.Text secondary={textProps.disabled} style={[styles.text, col === undefined ? {} : { color: col }, style]} {...textProps} />
      {icon && alignIcon === 'right' && (
        <IM.Icon
          color={textProps.secondary || textProps.disabled ? theme.textDetail : (color ?? iconColor ?? theme.text)}
          style={[styles.icon, styles.iconRight, iconStyle]}
          icon={icon}
          size={iconSize}
        />
      )}
    </IM.View>
  )
})

export default TextWithIcon

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    justifyContent: 'center',
  },
  iconLeft: {
    marginRight: 7,
  },
  iconRight: {
    marginLeft: 7,
  },
  text: { textAlignVertical: Platform.OS === 'web' ? 'auto' : 'center' },
})
