import { Buffer } from 'buffer'

export const base64Utils = {
  decode<T>(base64String: string) {
    return JSON.parse(Buffer.from(base64String, 'base64').toString()) as T
  },
  encode(object: object) {
    return Buffer.from(JSON.stringify(object)).toString('base64')
  },
}
