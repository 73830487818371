import { IM, IMLayout, IMStyle, SpacingProps, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'
import CountryFlag from 'react-native-country-flag'

import api from '../../apis/apiCalls'
import { Activity, TicketActivity } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import useRequest from '../../components/Infominds/hooks/useRequest'
import PressableTextIcon from '../../components/Infominds/PressableTextIcon'
import Text from '../../components/Text'
import { REFRESH_ACTIVITY_VIEW_EVENT_KEY } from '../../constants/EmitterKeys'
import useUserSettings from '../../hooks/useUserSettings'
import EditElementModal, { EditElementModalType } from '../../modals/EditElementModal'
import { ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'

export type ActivityDescriptionCardRef = {
  reset: () => void
}

enum ModalType {
  TECHNICAL = 'Technical',
  CUSTOMER = 'Customer',
  NEW_ACTIVITY = 'NewActivity',
}

type Props = {
  activity: TicketActivity
  spacing?: SpacingProps
  disabled?: boolean
  disableNextActivity?: boolean
}

const ActivityDescriptionCard = memo(
  forwardRef(function ActivityDescriptionCard(
    { activity, spacing, disabled = false, disableNextActivity = false }: Props,
    ref: ForwardedRef<ActivityDescriptionCardRef>
  ) {
    useImperativeHandle(
      ref,
      () => ({
        reset: () => {
          setTech(activity.technicalDescription)
          setCustomerNote(activity.customerDescription)
          setNewActivity(activity.nextActivityDescription)
        },
      }),
      []
    )

    const { i18n } = useLanguage()
    const { userSettings } = useUserSettings()
    const controller = useModalController<EditElementModalType>()

    const [tech, setTech] = useState(activity.technicalDescription)
    const [customerNote, setCustomerNote] = useState(activity.customerDescription)
    const [newActivity, setNewActivity] = useState(activity.nextActivityDescription)

    const requesting = useRef(false)
    const { request, loading } = useRequest(api.editActivity)
    const { emit } = useEvent({ key: REFRESH_ACTIVITY_VIEW_EVENT_KEY })

    useEffect(() => {
      if (loading === false && requesting.current) {
        emit()
        requesting.current = false
      }
    }, [loading])

    const code = useMemo(() => appUtils.customerLanguageToCountryCode(activity.customerLanguage), [])

    return (
      <>
        <IM.Card spacing={spacing} head={<CardLeftTitle text={i18n.t('DESCRIPTION')} />} noContentSpacing>
          <IM.View style={styles.card}>
            <ElementInput
              title={i18n.t('TECHNICAL_DESCRIPTION')}
              value={tech ?? ''}
              editable={!disabled}
              onPress={() => {
                controller.show({ title: i18n.t('EDIT_TECHNICAL_DESCRIPTION'), value: tech, id: ModalType.TECHNICAL })
              }}
            />
            <ElementInput
              title={i18n.t('CUSTOMER_DESCRIPTION')}
              value={customerNote ?? ''}
              editable={!disabled && (userSettings ? userSettings.allowEditingCustomerDescriptionOfActivity : false)}
              flagCode={code}
              onPress={() => {
                controller.show({ title: i18n.t('EDIT_CUSTOMER_DESCRIPTION'), value: customerNote, id: ModalType.CUSTOMER, flag: code })
              }}
            />
            <ElementInput
              title={i18n.t('NEW_ACTIVITY_DESCRIPTION')}
              value={newActivity ?? ''}
              editable={!disableNextActivity && !disabled}
              onPress={() => {
                controller.show({ title: i18n.t('EDIT_NEW_ACTIVITY_DESCRIPTION'), value: newActivity, id: ModalType.NEW_ACTIVITY })
              }}
            />
          </IM.View>
        </IM.Card>
        <EditElementModal
          controller={controller}
          onClose={(value, modalId) => {
            requesting.current = true
            switch (modalId) {
              case ModalType.TECHNICAL: {
                request({
                  id: activity.id,
                  technicalDescription: value ?? '',
                } as Activity)
                break
              }
              case ModalType.CUSTOMER: {
                request({
                  id: activity.id,
                  customerDescription: value ?? '',
                } as Activity)
                break
              }
              case ModalType.NEW_ACTIVITY: {
                request({
                  id: activity.id,
                  nextActivityDescription: value ?? '',
                } as Activity)
                break
              }
            }
          }}
        />
      </>
    )
  })
)

function ElementInput({
  value,
  editable,
  title,
  flagCode,
  onPress,
}: {
  value: string
  title: string
  editable: boolean
  flagCode?: string
  onPress: () => void
}) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View spacing="bottom">
      <IM.View style={[IMLayout.flex.row, IMLayout.flex.f1, styles.center]}>
        <Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{title}</Text>
        <PressableTextIcon
          icon={['fal', 'pen']}
          alignIcon="right"
          iconSize={12}
          color={editable ? theme.text : theme.textDetail}
          iconColor={editable ? theme.text : theme.textDetail}
          disabled={!editable}
          onPress={onPress}>
          {i18n.t('EDIT')}
        </PressableTextIcon>
      </IM.View>
      <IM.View style={[styles.content, { backgroundColor: theme.inputBox.background.disabled }]}>
        <Text>{value}</Text>
        {flagCode && <CountryFlag isoCode={flagCode} size={20} />}
      </IM.View>
    </IM.View>
  )
}

export default ActivityDescriptionCard

const styles = StyleSheet.create({
  center: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    borderRadius: 10,
    padding: 8,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  card: {
    paddingTop: 6,
    paddingBottom: 12,
    paddingHorizontal: 12,
  },
})
