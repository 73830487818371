import { IconProp } from '@fortawesome/fontawesome-svg-core'

export enum DetailHeaderType {
  SEARCH = 'SEARCH',
  FILTER = 'FILTER',
  LOCATION = 'LOCATION',
}

export enum TicketScreenViewType {
  LIST,
  LOCATION,
  CALENDAR,
}

export enum PlanningScreenViewType {
  LIST,
  PLANNER,
}

export type StringContextText = string | { text: string; icon: IconProp }

type Icon = { icon: IconProp; size: number }

export const MAP_ICON: Icon = { icon: ['fal', 'map-location-dot'], size: 20 }
export const CALENDAR_ICON: Icon = { icon: ['fal', 'calendar'], size: 18 }
export const LIST_ICON: Icon = { icon: ['fal', 'list'], size: 18 }
export const PLANNER_ICON: Icon = { icon: ['fal', 'chart-gantt'], size: 18 }
