import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import { LoadingType, VaultLockType } from '../../../types'
import { abortUtils } from '../../../utils/abortController'
import appUtils from '../../../utils/appUtils'
import { utils } from '../../../utils/utils'

export type Options<TResponse> = {
  id?: string
  onResult?: (response: TResponse) => VaultLockType
  onLoadedCallback?: (item: TResponse) => void
  onErrorCallback?: (err: unknown) => void
}

interface Result<TRequest, TResponse> {
  item: TResponse | undefined
  loadItem: (request: TRequest) => void
  loading: LoadingType
  setItem: Dispatch<SetStateAction<TResponse | undefined>>
}

export default function useControlledLoader<TRequest, TResponse>(
  getData: (request: TRequest, abortController?: AbortController) => Promise<TResponse>,
  options?: Options<TResponse>
): Result<TRequest, TResponse> {
  const hash = useRef(utils.generateUuid())
  const [item, setItem] = useState<TResponse>()
  const [loading, setLoading] = useState<LoadingType>('init')

  useEffect(() => {
    return () => {
      // Need to add component hash to prevent abort from other copies that get destroyed
      options && options.id && abortUtils.abort(options.id, hash.current)
    }
  }, [])

  const loader = useCallback(
    (request: TRequest) => {
      let controller: AbortController | undefined

      if (options && options.id) {
        // Hash should not be passed here because new call from different components should abort previous request to the same endpoint
        abortUtils.abort(options.id)
        controller = abortUtils.add(options.id, hash.current)
      }

      getData(request, controller)
        .then(result => {
          if (options === undefined || options?.onResult === undefined || options.onResult(result) === 'vault-unlocked') {
            setItem(() => {
              options?.onLoadedCallback?.(result)
              return result
            })

            setLoading(false)
          } else {
            setLoading('aborted')
          }
        })
        .catch(err => {
          if (appUtils.isAbortError(err)) {
            // setLoading('aborted')
            console.debug(`Aborted useControlledLoader - name: ${getData.name} -  id: ${options?.id ?? 'None'} - request:`, request)
            return
          }

          options?.onErrorCallback?.(err)
          console.error('Failed loader() on useControlledLoader:', err)

          setLoading('catched')
        })
        .finally(() => {
          options && options.id && abortUtils.remove(options.id)
        })

      return
    },
    [item]
  )

  const load = useCallback((requestData: TRequest) => {
    setItem(undefined)
    setLoading('reloading')
    loader(requestData)
  }, [])

  return { item, loadItem: load, loading, setItem }
}
