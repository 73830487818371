import { IMLayout } from '@infominds/react-native-components'
import { StyleSheet } from 'react-native'

import { PlannerTheme } from '../components/planner/types'
import { SCREEN_CONSTANTS } from '../components/screen/constants/constants'

const CONSTANTS = {
  toastVisibilityTime: 5000,
  appName: 'RX+ Service',
  maxLastUsedCustomers: 3,
  maxLastUsedCustomersTablet: 6,
  skeletonCards: 30,
  accessDataSkeletonCards: 12,
  customersChunkSize: 14,
  defaultChuckSize: 25,
  destinationsChuckSize: 25,
  maxNavBarElements: 4,
  maxTabletNavBarElements: 6,
  landscapeTabletMaxSearchBar: 250,
  portraitTabletMaxSearchBar: 130,
  landscapeTabletMaxFilterBar: 500,
  portraitTabletMaxFilterBar: 350,
  maxPhoneNumbers: 13,
  cardLeftWidth: 6,
  secondButtonBottom: 64,
  searchDeferredTimeout: 400,
  signatureTimeout: 700,
  verticalTextMinHeight: 70,
  margin: 6,
  disabledOpacityLight: 0.5,
  disabledOpacityDark: 0.38,
  minDataLengthToHaveInputBox: 2,
  noSelectionId: 'noSelectionId',
  resetCheckIcon: 2000,
  planner: {
    ITEM_HEIGHT: 54,
    HEADER_HEIGHT: 40,
    WEEK_CELL_WIDTH: 84.0 / 7,
    COLORS: {
      light: {
        background: {
          planner: '#ffffff',
          popover: '#ffffff',
        },
        border: {
          primary: '#e9e9e9',
          secondary: '#d2d2d2',
        },
        nonWorking: { background: '#F8F8F9', text: 'black' },
        selection: 'transparent',
        text: '#000000',
      } as PlannerTheme,
      dark: {
        background: {
          planner: '#161616',
          popover: '#252526',
        },
        border: {
          primary: '#2f2f2f',
          secondary: '#515151',
        },
        nonWorking: { background: '#1E1E1E', text: 'white' },
        selection: 'transparent',
        text: '#FFFFFF',
      } as PlannerTheme,
    },
  } as const,
}

export default CONSTANTS

export const closeActivityStyles = StyleSheet.create({
  header: {
    marginHorizontal: -16,
    paddingHorizontal: 16,
    paddingTop: 2 * IMLayout.verticalMargin,
    borderTopLeftRadius: SCREEN_CONSTANTS.headerRadius,
    borderTopRightRadius: SCREEN_CONSTANTS.headerRadius,
  },
})

export const SETTINGS_BORDER_RADIUS = SCREEN_CONSTANTS.headerRadius - 6
