import { IM } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import PlannerResourceFilter from './PlannerResourceFilter'

type Props = {
  resourceName: string
}

export default function PlannerResourceHeader({ resourceName }: Props) {
  return (
    <IM.View style={styles.container}>
      {resourceName}
      <PlannerResourceFilter />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
})
