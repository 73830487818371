import { CardButton, CardProps, IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import type { Contact } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import ChangeButton from '../../components/ChangeButton'
import Error from '../../components/Error'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import SkeletonText from '../../components/skeleton/SkeletonText'
import { Gender, InfiniteLoadingType, ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import ticketUtils from '../../utils/TicketUtils'

type Props = CardProps & {
  contact: Contact | undefined
  disableIcon?: boolean
  leftText?: string
  enableChange?: boolean
  loading?: InfiniteLoadingType
  hideButtons?: boolean
  showGender?: boolean
  onChange?: () => void
}

const CustomerContactCard = memo(function CustomerContactCard({
  contact,
  disableIcon,
  leftText,
  enableChange,
  selected,
  hideButtons,
  loading = false,
  showGender,
  onChange,
  ...other
}: Props) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const inactive = contact?.inactive
  const phone = contact?.phone?.at(0)
  const email = contact?.email?.at(0)
  const buttons: CardButton[] = []

  const handlePhonePress = () => {
    phone && appUtils.openPhone(phone.number, alert, i18n)
  }

  const handleEmailPress = () => {
    email && appUtils.openEmail(alert, i18n.t('OPEN_EMAIL_ERROR'), email.address)
  }

  if (phone) {
    buttons.push({
      icon: ['fal', 'phone'],
      ...appUtils.getCardButtonColor(!inactive, theme),
      overrideDisabled: true,
      onPress: inactive ? undefined : handlePhonePress,
    })
  }

  if (email) {
    buttons.push({
      icon: ['fal', 'envelope'],
      ...appUtils.getCardButtonColor(!inactive, theme),
      overrideDisabled: true,
      onPress: inactive ? undefined : handleEmailPress,
    })
  }

  const processGender = (gender: Gender) => {
    switch (gender) {
      case Gender.Female:
        return i18n.t('GENDER_FEMALE')
      case Gender.Male:
        return i18n.t('GENDER_MALE')
      case Gender.Neuter:
        return i18n.t('GENDER_NEUTRAL')
      default:
        return ''
    }
  }

  const opacity = inactive ? appUtils.getOpacity(colorScheme) : 1

  return (
    <IM.Card
      head={<CardLeftTitle color={selected ? theme.general.info : theme.card.accent.active} text={leftText} />}
      buttons={{
        buttons: hideButtons || loading !== false ? [] : buttons,
      }}
      {...other}
      selected={false}
      style={[other.style, { opacity }]}>
      {loading === false || loading === 'init' ? (
        <IM.View style={styles.content}>
          {contact ? (
            <>
              <IM.View style={IMLayout.flex.row}>
                <TextWithIcon
                  secondary={inactive}
                  icon={disableIcon ? undefined : ['fal', 'contact-book']}
                  iconSize={20}
                  style={{
                    fontWeight: IMStyle.typography.fontWeightBold,
                    fontSize: IMStyle.typography.fontSizeRegular,
                  }}
                  viewStyle={IMLayout.flex.f1}>
                  {contact.description}
                </TextWithIcon>
                {showGender && contact.gender !== Gender.None && (
                  <IM.View style={styles.gender}>
                    <IM.Text>{` (${processGender(contact.gender)})`}</IM.Text>
                  </IM.View>
                )}
              </IM.View>
              {(phone || email || contact.department || contact.position) && (
                <IM.View style={[IMLayout.flex.f1, disableIcon === false && styles.margin]} spacing="top">
                  {phone && <IM.Text secondary={inactive}>{phone.number}</IM.Text>}
                  {email && <IM.Text secondary={inactive}>{email.address}</IM.Text>}
                  {contact.department && <IM.Text secondary={inactive}>{contact.department}</IM.Text>}
                  {contact.position && <IM.Text secondary={inactive}>{contact.position}</IM.Text>}
                </IM.View>
              )}
            </>
          ) : (
            <IM.Text secondary>{i18n.t('NO_CONTACT_SELECTED')}</IM.Text>
          )}
          {enableChange && <ChangeButton onPress={() => onChange?.()} />}
        </IM.View>
      ) : (
        <>
          {loading === 'catched' ? (
            <>
              <Error />
              {enableChange && <ChangeButton onPress={() => onChange?.()} />}
            </>
          ) : (
            <>
              <SkeletonText width="70%" />
              <SkeletonText spacing="top" width="100%" />
            </>
          )}
        </>
      )}
    </IM.Card>
  )
})

export default CustomerContactCard

const styles = StyleSheet.create({
  content: { flex: 1, justifyContent: 'space-between' },
  margin: { margin: 4 },
  gender: { marginLeft: 4, justifyContent: 'flex-start' },
})
