import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Language } from '@infominds/react-native-components'
import dayjs, { OpUnitType } from 'dayjs'
import * as deLocale from 'dayjs/locale/de'
import * as enLocale from 'dayjs/locale/en'
import * as itLocale from 'dayjs/locale/it'
import React from 'react'
import { CellUnit, EventItem, Resource, SchedulerData, ViewType } from 'react-big-scheduler-stch'

import { Employee, PlanningTime, TravelTime } from '../apis/types/apiResponseTypes'
import EmployeeBadge from '../components/EmployeeBadge'
import { DnDType, EventTitle, PlannerViewType } from '../components/planner/types'
import { ActivityType, TimeFormat } from '../types'
import { base64Utils } from './base64Utils'
import TimeUtils from './TimeUtils'

type Options = { start?: OpUnitType; end?: OpUnitType }

export const plannerUtils = {
  toUTC(date: Date | dayjs.Dayjs | string | undefined, locale: Language, option?: Options) {
    if (date === undefined || date === '' || dayjs(date).isValid() === false) return undefined
    if (option && option.start !== undefined && option.end !== undefined) {
      console.error('Can not used both start and end option at the same time')
      return undefined
    }

    let toRet = dayjs(date).locale(locale)

    if (option?.start) {
      toRet = toRet.startOf(option?.start)
    }

    if (option?.end) {
      toRet = toRet.endOf(option?.end)
    }

    return toRet.toISOString()
  },
  getEventColor(activityType: ActivityType): { background: string; accent: string } {
    switch (activityType) {
      case 'EMail':
        return { background: '#25D07C', accent: '#277850' }
      case 'External':
        return { background: '#8C57FD', accent: '#4F318F' }
      case 'Hotline':
        return { background: '#5768FD', accent: '#3B47AF' }
      case 'Internal':
        return { background: '#9BA5FF', accent: '#3B47AF' }
      case 'RemoteService':
        return { background: '#45A8FC', accent: '#2A9BFC' }
      case 'None':
      default:
        return { background: '#5768FD', accent: '#3B47AF' }
    }
  },
  createResources(employees: Employee[] | undefined) {
    if (!employees) return []

    const toRet: Resource[] = []

    employees.forEach(employee => {
      const name = employee.firstName + ' ' + employee.lastName
      const resource: Resource = {
        id: employee.id,
        name,
        teams: employee.teams.map(el => el.id),
        leftComponent: <EmployeeBadge id={employee.id} name={name} showName={false} spacing="right" size={18} />,
      }

      toRet.push(resource)
    })

    return toRet
  },
  createEventsFromPlanningTimes(times: PlanningTime[] | undefined, view: PlannerViewType) {
    if (!times) return []

    return times.map(time => {
      const startDate = TimeUtils.revertSecondsToDate(time.from, new Date(time.date))
      const endDate = TimeUtils.revertSecondsToDate(time.until ?? 0, new Date(time.date))

      const titleObject: EventTitle = {
        ticketCode: time.ticketCode,
        activityCode: time.activityCode,
        activityType: time.activityType,
      }

      const toRet: EventItem = {
        id: time.id,
        resourceId: time.employeeId,
        title: base64Utils.encode(titleObject),
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        startResizable: view === 'day',
        endResizable: view === 'day',
        activityId: time.activityId,
        ticketId: time.ticketId,
        documentId: time.ticketDocumentId,
        activityCode: time.activityCode,
        ticketCode: time.ticketCode,
        bgColor: time.appointmentColorHex,
        type: DnDType.ACTIVITY,
      }

      return toRet
    })
  },
  createEventsFromTravelTime(travelTime: TravelTime, view: PlannerViewType) {
    const startDate = TimeUtils.revertSecondsToDate(travelTime.from, new Date(travelTime.date))
    const endDate = TimeUtils.revertSecondsToDate(travelTime.until ?? 0, new Date(travelTime.date))

    const toRet: EventItem = {
      id: travelTime.id,
      resourceId: travelTime.employeeId,
      title: '',
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      startResizable: view === 'day',
      endResizable: view === 'day',
      type: DnDType.TRAVEL_TIME,
    }

    return toRet
  },
  getDayjsLocale(appLocale: string | undefined) {
    switch (appLocale) {
      case 'en':
        return enLocale
      case 'it':
        return itLocale
      case 'de':
        return deLocale
      default:
        return enLocale
    }
  },
  convertToSchedulerViewType(view: PlannerViewType): ViewType {
    return view === 'day' ? ViewType.Day : view === 'week' ? ViewType.Week : ViewType.Month
  },
  getIcon(nextView: PlannerViewType): IconProp {
    switch (nextView) {
      case 'day':
        return ['fal', 'calendar-day']
      case 'week':
        return ['fal', 'calendar-week']
      case 'month':
        return ['fal', 'calendar']
    }
  },
  isNonWorkingTimeFunc: ({ cellUnit }: SchedulerData, time: string, from: number, to: number, workOnSaturday: boolean) => {
    if (cellUnit === CellUnit.Hour) {
      const hour = dayjs(new Date(time)).hour()
      const minute = dayjs(new Date(time)).minute()

      let toRet = true
      const [fromH, fromM] = TimeUtils.secondsToTime(from, TimeFormat.TIME).split(':')
      const [toH, toM] = TimeUtils.secondsToTime(to, TimeFormat.TIME).split(':')

      const result = dayjs(`2024-01-15 ${hour}:${minute}`).isBetween(`2024-01-15 ${fromH}:${fromM}`, `2024-01-15 ${toH}:${toM}`, 'minute', '[)')

      if (result) {
        toRet = false
      }

      return toRet
    }

    const date = new Date(time)
    const dayOfWeek = dayjs(date).isoWeekday()
    return workOnSaturday ? dayOfWeek === 7 : dayOfWeek === 6 || dayOfWeek === 7
  },
}
