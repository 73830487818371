import React, { memo } from 'react'
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd'

import ExtraActivityListCard, { ExtraActivityListCardProps } from './ExtraActivityListCard'

interface Props extends ExtraActivityListCardProps {
  isDragging?: boolean
  connectDragSource?: ConnectDragSource
  connectDragPreview?: ConnectDragPreview
}

const DndExtraActivityListCard = memo(function DndExtraActivityListCard({ isDragging, connectDragSource, connectDragPreview, ...props }: Props) {
  const dragContent = (
    <li>
      <ExtraActivityListCard {...props} />
    </li>
  )

  if (connectDragPreview === undefined || connectDragSource === undefined) return null

  return isDragging ? dragContent : <div>{connectDragPreview(connectDragSource(dragContent))}</div>
})

export default DndExtraActivityListCard
