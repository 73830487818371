import { useLanguage, useTheme } from '@infominds/react-native-components'
import { Checkbox, CheckboxProps, Divider } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { CheckboxOptionType } from 'antd/es/checkbox/Group'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../types'

export const groupStyle: React.CSSProperties = {
  overflowY: 'auto',
  flexWrap: 'nowrap',
  //@ts-ignore ok on web
  maxHeight: '46vh',
  maxWidth: 250,
}

const styles = StyleSheet.create({ divider: { marginTop: 2, marginBottom: 2 } })

interface Props {
  options: CheckboxOptionType<string>[]
  selected: string[] | undefined
  setSelected: (val: string[]) => void
}

const PlannerCheckboxGroup = ({ options, selected, setSelected }: Props) => {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const filteredOptions = options.filter(option => !option.disabled)
  const checkAll = filteredOptions.length === selected?.length
  const allDisabled = options.find(el => el.disabled === false || el.disabled === undefined) === undefined
  const indeterminate = selected ? selected.length > 0 && selected.length < filteredOptions.length : false

  const onCheckAll: CheckboxProps['onChange'] = (e: CheckboxChangeEvent) => {
    setSelected(e.target.checked ? filteredOptions.map(option => option.value) : [])
  }

  return (
    <>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAll} disabled={allDisabled} checked={checkAll} style={options[0].style}>
        {i18n.t('CHECK_ALL')}
      </Checkbox>
      <Divider style={{ ...styles.divider, ...{ backgroundColor: colorScheme === 'dark' ? theme.textPlaceholder : undefined } }} />
      <Checkbox.Group options={options} value={selected} onChange={setSelected} style={groupStyle} />
    </>
  )
}

export default memo(PlannerCheckboxGroup)
