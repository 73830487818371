/* eslint-disable react-native/no-inline-styles */

import { useTheme } from '@infominds/react-native-components'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'
import { EventItem } from 'react-big-scheduler-stch'

import { ThemeColorExpanded } from '../../types'
import { base64Utils } from '../../utils/base64Utils'
import PressableIcon from '../Infominds/PressableIcon'
import { EventTitle } from './types'

type Props = {
  eventItem: EventItem
  start: Dayjs
  end: Dayjs
  onDelete: () => void
  onDuplicate: () => void
}

export default function PlannerPopover({ end, eventItem, start, ...others }: Props) {
  const { onDelete, onDuplicate } = others
  const { theme } = useTheme<ThemeColorExpanded>()
  const object = base64Utils.decode<EventTitle>(eventItem.title)

  return (
    <div style={{ width: '300px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{object.activityCode}</span>
            <span style={{ fontWeight: 'light', fontSize: '12px' }}>{object.ticketCode}</span>
          </div>
          <div style={{ display: 'block' }}>
            <div style={{ display: 'flex' }}>
              <PressableIcon icon={['fal', 'copy']} onPress={onDuplicate} size={17} pressableRatio={0.6} />
              <PressableIcon icon={['fal', 'trash']} onPress={onDelete} size={17} color={theme.general.error} pressableRatio={0.6} />
            </div>
          </div>
        </div>
        <span style={{ fontWeight: 'light', paddingTop: 4 }}>
          {dayjs(start.toISOString()).format('D MMM YYYY LT')} - {dayjs(end.toISOString()).format('D MMM YYYY LT')}
        </span>
      </div>
    </div>
  )
}
