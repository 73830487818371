import { IM, SpacingProps } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import useQualityCheck from '../../hooks/useQualityCheck'
import { QualityCharacteristicDetails, QualityCheckType } from '../../types'
import QualityCheckSelector from '../selectors/QualityCheckSelector'
import Switch from '../Switch'
import NumberInput from './NumberInput'
import TextInput from './TextInput'

interface Props {
  type: QualityCheckType
  selectorTitle: string
  qualityCharacteristicId: string
  qualityCharacteristicDetails?: QualityCharacteristicDetails[]
  qualityCharacteristicDecimal?: number
  serialNumberId: string
  spacing?: SpacingProps
}

function QualityInput({
  type,
  spacing,
  selectorTitle,
  qualityCharacteristicDecimal,
  qualityCharacteristicId,
  qualityCharacteristicDetails,
  serialNumberId,
}: Props) {
  const { getQualityValue, setQualityValue } = useQualityCheck()

  return (
    <IM.View spacing={spacing}>
      {type === 'Text' && (
        <TextInput
          value={getQualityValue(qualityCharacteristicId, serialNumberId, 'value')}
          onChangeText={newVal =>
            setQualityValue({ characteristicsId: qualityCharacteristicId, serialNumberId, value: newVal === '' ? undefined : newVal })
          }
        />
      )}
      {type === 'Numeric' && (
        <NumberInput
          value={getQualityValue(qualityCharacteristicId, serialNumberId, 'value')}
          decimals={qualityCharacteristicDecimal}
          onChangeText={newVal =>
            setQualityValue({ characteristicsId: qualityCharacteristicId, serialNumberId, value: newVal === '' ? undefined : newVal })
          }
        />
      )}
      {type === 'Boolean' && (
        <Switch
          style={styles.switch}
          value={getQualityValue(qualityCharacteristicId, serialNumberId, 'value') === 'true' ? true : false}
          onValueChange={newVal => setQualityValue({ characteristicsId: qualityCharacteristicId, serialNumberId, value: newVal ? 'true' : 'false' })}
        />
      )}
      {type === 'Selection' && qualityCharacteristicDetails && (
        <QualityCheckSelector
          id={getQualityValue(qualityCharacteristicId, serialNumberId, 'value')}
          screenTitle={selectorTitle}
          data={qualityCharacteristicDetails}
          onChange={newVal => setQualityValue({ characteristicsId: qualityCharacteristicId, serialNumberId, value: newVal ? newVal.id : undefined })}
          alignRight
        />
      )}
      {type === 'None' && <></>}
    </IM.View>
  )
}

export default memo(QualityInput)

const styles = StyleSheet.create({
  switch: {
    alignItems: 'flex-end',
  },
})
