import { IM, useTheme } from '@infominds/react-native-components'
import { Badge } from 'antd'
import React, { memo } from 'react'

import { ThemeColorExpanded } from '../../types'

interface Props {
  count: number
  badgeStyle?: React.CSSProperties
  disabled?: boolean
}

function PlannerFilterIcon({ count, badgeStyle, disabled }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <Badge count={disabled ? 0 : count} showZero={!disabled} color={theme.general.info} size="small" overflowCount={99} style={badgeStyle}>
      <IM.Icon icon={['fal', 'filter']} />
    </Badge>
  )
}

export default memo(PlannerFilterIcon)
