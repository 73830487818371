import { IM, IMLayout, SpacingProps, useDimensions, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect, useMemo, useRef } from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import CellText from '../../components/CellText'
import Error from '../../components/Error'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import Pressable from '../../components/Infominds/Pressable'
import NavigationIcon from '../../components/NavigationIcon'
import SkeletonText from '../../components/skeleton/SkeletonText'
import { REQUEST_CLASSIFICATION } from '../../constants/Keys'
import ClassificationModal from '../../modals/common/ClassificationModal'
import { TicketStackParamList } from '../../navigation/types'
import { ClassificationParams, ClassificationType } from '../../types'
import appUtils from '../../utils/appUtils'

interface Props {
  id: string
  type: ClassificationType
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  onlyRead?: boolean
}

const ClassificationCard = memo(function ClassificationCard({ spacing, id, type, style, onlyRead }: Props) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()
  const controller = useModalController<ClassificationParams>()
  const firstRendered = useRef<number | undefined>(undefined)

  const { loadItem, item, loading } = useControlledLoader(api.getTicketClassification, { id: REQUEST_CLASSIFICATION })

  useEvent({ key: appUtils.classificationChangeEvent(type) }, () => refresh())

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => {
    firstRendered.current = undefined
    loadItem({ fkIds: [id] })
  }

  const hasValue = useMemo(() => {
    let toRet = false

    if (!item?.classifications?.length) return toRet

    item.classifications.forEach(el => {
      if (el.classificationDescription) toRet = true
    })

    return toRet
  }, [item])

  return (
    <>
      <IM.Card head={<CardLeftTitle text={i18n.t('CLASSIFICATION')} />} spacing={spacing} style={[styles.container, style]} noContentSpacing>
        <IM.View style={styles.contentContainer}>
          {loading === false ? (
            <Pressable
              disabled={onlyRead}
              onPress={() => {
                isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                  ? navigation.navigate('TicketCommonStack', {
                      screen: 'EditClassification',
                      params: { id, type, values: item?.classifications ?? [] },
                    })
                  : controller.show({ id, type, values: item?.classifications ?? [] })
              }}>
              <IM.View style={[IMLayout.flex.row, styles.align]}>
                <IM.View style={IMLayout.flex.f1}>
                  {!hasValue ? (
                    <IM.Text secondary>{i18n.t('NO_CLASSIFICATION')}</IM.Text>
                  ) : (
                    <>
                      {item?.classifications.map((elem, index) => {
                        if (elem.classificationDescription && firstRendered.current === undefined) {
                          firstRendered.current = index
                        }

                        return (
                          <IM.View key={elem.definitionId}>
                            {elem.classificationDescription && (
                              <CellText
                                title={elem.definitionDescription}
                                value={elem.classificationDescription}
                                style={IMLayout.flex.f6}
                                disableSpacing={firstRendered.current === index}
                                forceShowTitle
                                forceSpacingTable
                              />
                            )}
                          </IM.View>
                        )
                      })}
                    </>
                  )}
                </IM.View>
                {!onlyRead && <NavigationIcon enableTableStyle />}
              </IM.View>
            </Pressable>
          ) : (
            <IM.View spacing="horizontal">
              {loading === 'catched' ? (
                <Error />
              ) : (
                <>
                  <SkeletonText width="50%" spacing="top" />
                  <SkeletonText width="80%" spacing="top" />
                  <SkeletonText width="95%" spacing="vertical" />
                </>
              )}
            </IM.View>
          )}
        </IM.View>
      </IM.Card>
      <ClassificationModal controller={controller} />
    </>
  )
})

export default ClassificationCard

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  contentContainer: {
    margin: 6,
  },
  align: {
    justifyContent: 'space-between',
  },
})
