import { IMLayout, useLanguage } from '@infominds/react-native-components'
import { ListRenderItemInfo } from '@shopify/flash-list'
import React from 'react'
import { DnDSource, EventItem, SchedulerData } from 'react-big-scheduler-stch'

import { ExtraActivity } from '../../cards/activity/ExtraActivityListCard'
import usePlanner from '../../hooks/usePlanner'
import FlashListData from '../FlashListData'
import { DnDType } from './types'

type Props = {
  dndSource: DnDSource
  newEvent?: (schedulerData: SchedulerData, slotId: string, slotName: string, start: string, end: string, type: DnDType, item: EventItem) => void
}

export default function PlannerExtraActivityList({ dndSource, newEvent }: Props) {
  const { i18n } = useLanguage()
  const { schedulerData } = usePlanner()

  const RenderItem = dndSource.getDragSource()

  const renderItem = (elem: ListRenderItemInfo<ExtraActivity | string>) => {
    return (
      <RenderItem
        schedulerData={schedulerData}
        newEvent={newEvent}
        activity={elem.item}
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {
            marginTop: IMLayout.horizontalMargin,
            marginBottom: 0,
            marginHorizontal: IMLayout.horizontalMargin,
          },
        ]}
      />
    )
  }

  return (
    <FlashListData
      data={[
        {
          name: i18n.t('TRAVEL_TIME'),
        },
      ]}
      loading={false}
      noDataMessage={''}
      renderItem={renderItem}
      marginTop={0}
    />
  )
}
