import { useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React from 'react'
import { useRecoilValue } from 'recoil'

import OnlineView from '../../components/offline/OnlineView'
import { PlannerProvider } from '../../components/planner/PlannerContext'
import PlannerHeader from '../../components/planner/PlannerHeader'
import ScreenContext from '../../components/screen/contexts/ScreenContext'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import FilterDetailHeaderContainer from '../../components/screen/headers/containers/FilterDetailHeaderContainer'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import FilterDetailHeader from '../../components/screen/headers/FilterDetailHeader'
import PlanningHeader from '../../components/screen/headers/PlanningHeader'
import { Screen } from '../../components/screen/Screen'
import { PlanningScreenViewType } from '../../components/screen/types'
import FilterContext from '../../contexts/FilterContext'
import useUserSettings from '../../hooks/useUserSettings'
import { PlanningStackScreenProps } from '../../navigation/types'
import { activityPlanningFilterEnableAtom, plannerViewAtom } from '../../utils/stateManager'
import PlannerView from '../../views/planning/PlannerView'
import PlanningView from '../../views/planning/PlanningView'

export default function PlanningScreen({ navigation }: PlanningStackScreenProps<'Planning'>) {
  const { i18n } = useLanguage()
  const { sessionKey } = useAuthentication()
  const { userSettings } = useUserSettings()

  const enabled = useRecoilValue(activityPlanningFilterEnableAtom)
  const plannerView = useRecoilValue(plannerViewAtom(sessionKey))

  const handleSDNavigation = () => {
    navigation.navigate('PlanningCommonStack', { screen: 'Filter' })
  }

  return (
    <SearchProvider>
      <FilterContext.Consumer>
        {context => {
          let activeFilters = false
          context?.filters.forEach(filter =>
            filter.elements.forEach(el => {
              if (el.active) activeFilters = true
            })
          )
          const activeGroups = context?.groups.filter(el => el.active)
          const activeOrder = context?.orders.filter(el => el.active)

          return (
            <Screen>
              <Screen.Header>
                <ScreenContext.Consumer>
                  {screenContext => {
                    return (
                      <PlanningHeader
                        tabletSearchComponent={
                          screenContext?.planningView === PlanningScreenViewType.LIST ? (
                            <SearchDetailHeaderContainer enabledOffline dividers={['right']} enableQR />
                          ) : (
                            <PlannerHeader />
                          )
                        }
                        tabletFilterComponent={
                          screenContext?.planningView === PlanningScreenViewType.LIST ? (
                            <FilterDetailHeaderContainer dividers={['right']}>
                              <FilterDetailHeader enabled={enabled} onSDNavigation={handleSDNavigation} />
                            </FilterDetailHeaderContainer>
                          ) : undefined
                        }
                        highlightFilter={activeFilters || activeGroups?.length !== 0 || activeOrder?.length !== 0}
                        enabledOffline={userSettings?.offlineSynchronizationOfThePlanningList}
                      />
                    )
                  }}
                </ScreenContext.Consumer>
              </Screen.Header>
              <Screen.DetailHeader>
                <SearchDetailHeaderContainer enableQR />
                <FilterDetailHeaderContainer>
                  <FilterDetailHeader enabled={enabled} onSDNavigation={handleSDNavigation} />
                </FilterDetailHeaderContainer>
              </Screen.DetailHeader>
              <Screen.Content>
                <ScreenContext.Consumer>
                  {screenContext => {
                    return (
                      <OnlineView showOfflineIndicator showInOffline={userSettings?.offlineSynchronizationOfThePlanningList}>
                        {screenContext?.planningView === PlanningScreenViewType.LIST ? (
                          <PlanningView />
                        ) : (
                          <PlannerProvider resourceLabel={i18n.t('EMPLOYEE')} plannerView={plannerView}>
                            <PlannerView />
                          </PlannerProvider>
                        )}
                      </OnlineView>
                    )
                  }}
                </ScreenContext.Consumer>
              </Screen.Content>
            </Screen>
          )
        }}
      </FilterContext.Consumer>
    </SearchProvider>
  )
}
