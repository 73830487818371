import { IM, IMLayout, useDimensions, useEvent } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { Animated, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import AdditionalFieldsCard from '../../cards/common/AdditionalFieldsCard'
import TicketArticleAccessoryCard from '../../cards/ticketDetail/ticketArticle/TicketArticleAccessoryCard'
import TicketArticleContractCard from '../../cards/ticketDetail/ticketArticle/TicketArticleContractCard'
import TicketArticleCounterReadingCard from '../../cards/ticketDetail/ticketArticle/TicketArticleCounterReadingCard'
import TicketArticleCustomerContactIdCard from '../../cards/ticketDetail/ticketArticle/TicketArticleCustomerContactIdCard'
import TicketArticleDetailCard from '../../cards/ticketDetail/ticketArticle/TicketArticleDetailCard'
import TicketArticleInfoCard from '../../cards/ticketDetail/ticketArticle/TicketArticleInfoCard'
import TicketArticleMonitoringCard from '../../cards/ticketDetail/ticketArticle/TicketArticleMonitoringCard'
import TicketArticlePastTicketsCard from '../../cards/ticketDetail/ticketArticle/TicketArticlePastTicketsCard'
import TicketArticleWarrantyCard from '../../cards/ticketDetail/ticketArticle/TicketArticleWarrantyCard'
import InfoboxButton from '../../components/InfoboxButton'
import DynamicView from '../../components/Infominds/DynamicView'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import ScrollViewData from '../../components/ScrollViewData'
import { TICKET_ARTICLE_INFOBOX_ID } from '../../constants/ButtonIds'
import { REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_INFOBOX_FILES, REQUEST_TICKET_ARTICLE } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import { TicketStackParamList } from '../../navigation/types'

interface Props {
  ticketId: string
  activityId: string
  articleId: string
  buttonAnimationValue: Animated.Value
}

export default function TicketArticleView({ ticketId, articleId, activityId, buttonAnimationValue }: Props) {
  const { isOnline } = useDataProvider()
  const { isSmallDevice } = useDimensions()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()

  const {
    item: articles,
    loadItem: getArticles,
    loading,
    setItem: setArticles,
  } = useControlledLoader(api.getTicketArticle, { id: REQUEST_TICKET_ARTICLE })
  const {
    item: files,
    loadItem: loadFiles,
    loading: loadingFiles,
  } = useControlledLoader(api.getInfoboxFiles, {
    id: REQUEST_INFOBOX_FILES,
  })

  useEvent({ key: REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY }, () => refreshInfobox())

  useEffect(() => {
    refresh()
  }, [articleId, ticketId])

  useEffect(() => {
    loading === false && refreshInfobox()
  }, [loading])

  const refresh = () => {
    getArticles({ ticketId, id: articleId })
  }

  const refreshInfobox = () => {
    articles && articles.length !== 0 && articles[0].serialnumberId && loadFiles({ infoboxTyp: 'Serialnumber', id: articles[0].serialnumberId })
  }

  const article = articles?.at(0)

  return (
    <>
      <ScrollViewData refresh={refresh} buttonId={TICKET_ARTICLE_INFOBOX_ID} loading={loading === false && article !== undefined ? false : loading}>
        {article && (
          <>
            <TicketArticleInfoCard article={article} spacing="bottom" />
            <DynamicView>
              <IM.View style={IMLayout.flex.f4} spacing="bottom">
                <TicketArticleCustomerContactIdCard
                  id={article.id}
                  articleId={article.articleId}
                  addressId={article.addressId}
                  contactId={article.contactId}
                  serialNumberId={article.serialnumberId}
                  changeable={article.serialnumberId !== undefined && isOnline}
                  style={styles.grow}
                  onChangedContactId={contactId => {
                    //@ts-ignore: ok
                    setArticles(prev => [{ ...prev[0], contactId }])
                  }}
                />
              </IM.View>
              {((isSmallDevice && article.serialnumberCustomerWarrantyStart && article.serialnumberCustomerWarrantyEnd) || !isSmallDevice) && (
                <IM.View style={IMLayout.flex.f4} spacing={isSmallDevice ? 'bottom' : ['bottom', 'left']}>
                  <TicketArticleWarrantyCard article={article} style={styles.grow} />
                </IM.View>
              )}
              {article.serialnumberMonitoring !== 'None' && (
                <IM.View style={IMLayout.flex.f4} spacing={isSmallDevice ? 'bottom' : ['bottom', 'left']}>
                  <TicketArticleMonitoringCard article={article} style={styles.grow} />
                </IM.View>
              )}
            </DynamicView>
            {article.serialnumberId && (
              <TicketArticleCounterReadingCard serialNumberId={article.serialnumberId} spacing="bottom" activityId={activityId} />
            )}
            {article.contracts.length !== 0 && <TicketArticleContractCard contracts={article.contracts} spacing="bottom" />}
            {article.serialnumberId && isOnline && <TicketArticleAccessoryCard serialNumberId={article.serialnumberId} spacing="bottom" />}
            {isOnline && <TicketArticleDetailCard articleId={article.articleId} graphicId={article.grafikId} spacing="bottom" />}
            {article.serialnumberId && isOnline && (
              <>
                <AdditionalFieldsCard id={article.serialnumberId} type="Serialnumber" spacing="bottom" />
                <TicketArticlePastTicketsCard serialNumberId={article.serialnumberId} />
              </>
            )}
          </>
        )}
      </ScrollViewData>
      {article && article.serialnumberId !== undefined && (
        <InfoboxButton
          id={TICKET_ARTICLE_INFOBOX_ID}
          value={buttonAnimationValue}
          loading={loadingFiles}
          onPress={() => {
            article &&
              article.serialnumberId &&
              files &&
              navigation.navigate('TicketInfoboxStack', {
                screen: 'InfoboxFolders',
                params: {
                  id: article.serialnumberId,
                  type: 'Serialnumber',
                  subTitle: article.articleCode + (article.serialnumber ? ` (${article.serialnumber})` : ''),
                },
              })
          }}
          text={files?.length.toString() ?? 'NaN'}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  grow: {
    flexGrow: 1,
  },
})
