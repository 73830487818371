import dayjs from 'dayjs'
import React, { ForwardedRef, forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Scheduler, { DATE_FORMAT, EventItem, Resource } from 'react-big-scheduler-stch'

import './style.scss'

import usePlanner from '../../hooks/usePlanner'
import { plannerUtils } from '../../utils/plannerUtils'
import { utils } from '../../utils/utils'
import { PlannerProps, PlannerRef, PlannerView, PlannerViewType } from './types'

const Planner = ({ dndSources, style, ...others }: PlannerProps, ref: ForwardedRef<PlannerRef>) => {
  const { onRefresh, onMove, onMoveEnd, onMoveStart, renderResourceHeader, renderItem, renderItemPopover, onDateChange } = others

  useImperativeHandle(ref, () => ({
    next: _onNext,
    previous: _onPrevious,
    pick: _onDatePick,
    remove: _onRemove,
    add: _onAdd,
    updateResources: _updateResources,
  }))

  const { schedulerData, view, events } = usePlanner()
  const [_, setId] = useState(utils.generateUuid())
  const parentRef = useRef(null)

  useEffect(() => {
    if (!events) return

    schedulerData.setEvents(events)
    _update()
  }, [events])

  useEffect(() => {
    _onViewChange(view)
  }, [view])

  const _onPrevious = () => {
    schedulerData.prev()
    onDateChange(prev => ({ ...(prev as PlannerView), label: schedulerData.getDateLabel(), date: new Date(schedulerData.startDate).toISOString() }))
    onRefresh(new Date(schedulerData.startDate))
    _update()
  }

  const _onNext = () => {
    schedulerData.next()
    onDateChange(prev => ({ ...(prev as PlannerView), label: schedulerData.getDateLabel(), date: new Date(schedulerData.startDate).toISOString() }))
    onRefresh(new Date(schedulerData.startDate))
    _update()
  }

  const _onDatePick = (date: Date) => {
    if (dayjs(date).isSame(schedulerData.startDate, view)) {
      return
    }

    schedulerData.setDate(dayjs(date).format(DATE_FORMAT))
    onDateChange(prev => ({ ...(prev as PlannerView), label: schedulerData.getDateLabel(), date: new Date(schedulerData.startDate).toISOString() }))
    onRefresh(new Date(schedulerData.startDate))
    _update()
  }

  const _onViewChange = (newViewType: PlannerViewType) => {
    const newView = plannerUtils.convertToSchedulerViewType(newViewType)

    if (newView === schedulerData.viewType) return

    schedulerData.setViewType(newView, false, false)
    onDateChange(prev => ({ ...(prev as PlannerView), label: schedulerData.getDateLabel(), date: new Date(schedulerData.startDate).toISOString() }))
    onRefresh(new Date(schedulerData.startDate))
  }

  const _onAdd = (newEvent: EventItem | EventItem[]) => {
    if (Array.isArray(newEvent)) {
      newEvent.forEach(ev => schedulerData.addEvent(ev))
    } else {
      schedulerData.addEvent(newEvent)
    }
  }

  const _onMove = (event: EventItem, newSlotId: string, newSlotName: string, newStart: string, newEnd: string) => {
    schedulerData.moveEvent(event, newSlotId, newSlotName, newStart, newEnd)
  }

  const _onUpdateStart = (event: EventItem | EventItem[], newStart: string) => {
    if (Array.isArray(event)) {
      event.forEach(ev => schedulerData.updateEventStart(ev, newStart))
    } else {
      schedulerData.updateEventStart(event, newStart)
    }
  }

  const _onUpdateEnd = (event: EventItem | EventItem[], newEnd: string) => {
    if (Array.isArray(event)) {
      event.forEach(ev => schedulerData.updateEventEnd(ev, newEnd))
    } else {
      schedulerData.updateEventEnd(event, newEnd)
    }
  }

  const _onRemove = (event: EventItem | EventItem[]) => {
    if (Array.isArray(event)) {
      event.forEach(ev => schedulerData.removeEvent(ev))
    } else {
      schedulerData.removeEvent(event)
    }
  }

  const _updateResources = (currentResource: Resource[]) => {
    const totalAdded = currentResource.filter(x => schedulerData.resources.find(resource => resource.id === x.id) === undefined)
    const totalRemoved = schedulerData.resources.filter(x => currentResource.find(resource => resource.id === x.id) === undefined)

    for (const added of totalAdded) {
      schedulerData.addResource(added, true)
    }

    for (const removed of totalRemoved) {
      schedulerData.removeResource(removed, true)
    }

    _update()
  }

  const _update = () => {
    setId(utils.generateUuid())
  }

  return (
    <div ref={parentRef} style={{ ...{ width: '100%', height: '100%' }, ...style }}>
      <Scheduler
        key={parentRef.current}
        parentRef={parentRef}
        dndSources={dndSources}
        schedulerData={schedulerData}
        nextClick={_onNext}
        prevClick={_onPrevious}
        moveEvent={(_data, event, slotId, slotName, start, end) => {
          _onMove(event, slotId, slotName, start, end)
          onMove(event, slotId, slotName, start, end)
        }}
        updateEventStart={(_data, event, newStart) => {
          _onUpdateStart(event, newStart)
          onMoveStart(event, newStart)
        }}
        updateEventEnd={(_data, event, newEnd) => {
          _onUpdateEnd(event, newEnd)
          onMoveEnd(event, newEnd)
        }}
        onSelectDate={() => {
          return
        }}
        onViewChange={() => {
          return
        }}
        eventItemTemplateResolver={(_data, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight) =>
          renderItem(event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight)
        }
        eventItemPopoverTemplateResolver={(_date, event, title, start, end, statusColor) => renderItemPopover(event, title, start, end, statusColor)}
        customerResourceHeader={resourceName => renderResourceHeader?.(resourceName)}
      />
    </div>
  )
}

export default memo(forwardRef(Planner))
