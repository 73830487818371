import { IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'

import api from '../../apis/apiCalls'
import { InvoiceType } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useSearch from '../../components/screen/hooks/useSearch'
import SectionList from '../../components/SectionList'
import { RenderInvoiceType } from '../../components/selectors/InvoiceTypeSelector'
import SkeletonText from '../../components/skeleton/SkeletonText'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_INVOICE_TYPE } from '../../constants/Keys'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'

interface Props {
  invoiceTypeId: string | undefined
  onChange: (priorityId: string) => void
}

export default function EditInvoiceTypeView({ invoiceTypeId, onChange }: Props) {
  const { i18n } = useLanguage()
  const { search } = useSearch()
  const [selectedId, setSelectedId] = useState<string | undefined>(invoiceTypeId)

  const {
    item: data,
    loadItem,
    loading,
  } = useControlledLoader(api.getInvoiceType, {
    id: REQUEST_INVOICE_TYPE,
  })

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => loadItem()

  const handlePress = (id: string) => {
    setSelectedId(id)
    onChange(id)
  }

  const sections: ListSection<InvoiceType>[] = useMemo(() => {
    const displayData: ListSection<InvoiceType>[] = []

    if (data?.length && loading !== 'reloading' && loading !== 'aborted') {
      const filteredData = appUtils.filter(data, search, ['code', 'description'])

      displayData.push({
        data: filteredData,
      })
    }

    return displayData
  }, [data, loading, search])

  return (
    <SectionList
      sections={sections}
      loading={loading}
      renderItem={({ item }) => <RenderInvoiceType item={item} selectedId={selectedId} onPress={() => handlePress(item.id)} />}
      noDataMessage={i18n.t('NO_PRIORITY_FOUND')}
      skeletonElements={CONSTANTS.skeletonCards}
      skeletonComponent={<SkeletonText height={20} width="100%" spacing="top" />}
      skeletonTopSpacing
      onRefresh={refresh}
      contentContainerStyle={{ margin: IMLayout.verticalMargin, paddingBottom: 3 * IMLayout.verticalMargin }}
    />
  )
}
