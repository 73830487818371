import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'

import { QualityCheckNote, QualityCheckValue } from '../contexts/QualityCheckContext'
import { CharacteristicsModified, QualityCharacteristicCheck, QualityCheckType, QualityPost, QualityValue } from '../types'

const qualityUtils = {
  qualityMandatoryCheck(value: string | undefined, check: QualityCharacteristicCheck): 'passed' | 'failed' {
    if (check.isMandatory) {
      return value === undefined || value === '' ? 'failed' : 'passed'
    } else {
      return 'passed'
    }
  },
  qualityTargetComparisonCheck(type: QualityCheckType, value: string | undefined, check: QualityCharacteristicCheck): 'passed' | 'failed' {
    if (!check.targetCompare) return 'passed'

    switch (type) {
      case 'None': {
        return 'passed'
      }
      case 'Selection': {
        if (check.targetSelectionDetailId) {
          return check.targetSelectionDetailId === value ? 'passed' : 'failed'
        } else {
          return 'passed'
        }
      }
      case 'Text': {
        if (check.targetText) {
          return check.targetText === value ? 'passed' : 'failed'
        } else {
          return 'passed'
        }
      }
      case 'Numeric': {
        if (value === undefined) {
          if (check.targetNumericFrom !== undefined || check.targetNumericUntil !== undefined) return 'failed'
          return 'passed'
        }

        try {
          const number = parseInt(value, 10)

          if (check.targetNumericFrom !== undefined && check.targetNumericUntil !== undefined) {
            return number <= check.targetNumericUntil && number >= check.targetNumericFrom ? 'passed' : 'failed'
          } else if (check.targetNumericFrom !== undefined && check.targetNumericUntil === undefined) {
            return number >= check.targetNumericFrom ? 'passed' : 'failed'
          } else if (check.targetNumericFrom === undefined && check.targetNumericUntil !== undefined) {
            return number <= check.targetNumericUntil ? 'passed' : 'failed'
          } else {
            return 'passed'
          }
        } catch (err) {
          console.error(err)
          return 'failed'
        }
      }
      case 'Boolean': {
        return (check.targetBoolean ? 'true' : 'false') === value ? 'passed' : 'failed'
      }
    }
  },
  closingQualityCheck(quality: CharacteristicsModified[] | undefined) {
    let checkPassed = true

    quality?.forEach(el => {
      if (el.qualityCharacteristicCheck) {
        const mandatoryCheck = qualityUtils.qualityMandatoryCheck(el.value, el.qualityCharacteristicCheck)
        const targetComparisonCheck = qualityUtils.qualityTargetComparisonCheck(
          el.qualityCharacteristicFieldTyp,
          el.value,
          el.qualityCharacteristicCheck
        )

        if (mandatoryCheck === 'failed' || targetComparisonCheck === 'failed') {
          checkPassed = false
        }
      }
    })

    return checkPassed
  },
  prepareQualityCheck: (
    characteristics: CharacteristicsModified[],
    qualityValues: QualityCheckValue[],
    qualityNotes: QualityCheckNote[],
    type: 'POST' | 'PATCH'
  ) => {
    const quality: QualityPost[] = []

    const clone = cloneDeep(characteristics)

    if (clone !== undefined) {
      qualityValues.forEach(value => {
        const foundIndex = clone.findIndex(el => el.serialnumberId === value.serialNumberId && el.qualityCharacteristicId === value.characteristicsId)
        clone[foundIndex].value = value.value
      })

      qualityNotes.forEach(note => {
        const foundIndex = clone.findIndex(el => el.serialnumberId === note.serialNumberId && el.qualityCharacteristicId === note.characteristicsId)
        clone[foundIndex].note = note.note
      })
    }

    const grouped = groupBy(clone, el => el.serialnumberId)

    Object.keys(grouped).forEach(key => {
      const elements: QualityValue[] = []

      grouped[key].forEach(el => {
        if (type === 'POST' ? el.id === undefined : el.id) {
          if (el.value !== undefined) {
            elements.push({
              qualityCharacteristicId: el.qualityCharacteristicId,
              value: el.value,
              note: el.note,
            })
          }
        }
      })

      if (elements.length > 0) {
        quality.push({
          id: grouped[key][0].id,
          activityId: grouped[key][0].activityId,
          serialnumberId: grouped[key][0].serialnumberId,
          values: elements,
        })
      }
    })

    return quality
  },
}

export default qualityUtils
