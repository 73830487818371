import React, { memo } from 'react'
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd'

import ActivityListCard, { ActivityListCardProps } from './ActivityListCard'

interface Props extends ActivityListCardProps {
  isDragging?: boolean
  connectDragSource?: ConnectDragSource
  connectDragPreview?: ConnectDragPreview
}

const DndActivityListCard = memo(function DndActivityListCard({ isDragging, connectDragSource, connectDragPreview, ...props }: Props) {
  const dragContent = (
    <li>
      <ActivityListCard {...props} />
    </li>
  )

  if (connectDragPreview === undefined || connectDragSource === undefined) return null

  return isDragging ? dragContent : <div>{connectDragPreview(connectDragSource(dragContent))}</div>
})

export default DndActivityListCard
