import { IM, IMLayout, IMStyle, SpacingProps, useDimensions, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { useSetRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import { ActivitySparePart } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import CardButton from '../../components/CardButton'
import CellText from '../../components/CellText'
import Chip from '../../components/Chip'
import DynamicView from '../../components/Infominds/DynamicView'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import Pressable from '../../components/Infominds/Pressable'
import Separator from '../../components/Infominds/Separator'
import NavigationIcon from '../../components/NavigationIcon'
import SkeletonText from '../../components/skeleton/SkeletonText'
import TextTable from '../../components/TextTable'
import { EDIT_ACTIVITY_SPARE_PARTS_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_ACTIVITY_SPARE_PARTS } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useUserSettings from '../../hooks/useUserSettings'
import { ActivityStackParamList } from '../../navigation/types'
import { ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import { numberUtils } from '../../utils/numberUtils'
import { shoppingCartAtom, shoppingCartRemovalAtom } from '../../utils/stateManager'
import ticketUtils from '../../utils/TicketUtils'

type Props = {
  id: string
  ticketId: string
  activityCode: string
  spacing?: SpacingProps
  onlyRead?: boolean
}

const firstColumn = IMLayout.flex.f1
const secondColumn = IMLayout.flex.f1
const thirdColumn = IMLayout.flex.f1
const fourthColumn = IMLayout.flex.f3
const fifthColum = IMLayout.flex.f1
const sixthColum = { flex: 0.6 }
const seventhColum = { flex: 1 }

const ActivitySparePartsCard = memo(function ActivitySparePartsCard({ id, ticketId, activityCode, spacing, onlyRead }: Props) {
  const { isOnline } = useDataProvider()
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { userSettings } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const setInstallationInitialValue = useSetRecoilState(shoppingCartAtom)
  const setRemovalInitialValue = useSetRecoilState(shoppingCartRemovalAtom)

  const navigation = useNavigation<NavigationProp<ActivityStackParamList>>()
  const { item: parts, loadItem: getParts, loading } = useControlledLoader(api.getActivitySpareParts, { id: REQUEST_ACTIVITY_SPARE_PARTS })

  useEvent({ key: EDIT_ACTIVITY_SPARE_PARTS_EVENT_KEY }, () => refresh())

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    if (loading === false && parts !== undefined) {
      const initInstallation = ticketUtils.initShoppingCart(parts, 'Installation')

      setInstallationInitialValue(initInstallation)
      setRemovalInitialValue(ticketUtils.initShoppingCart(parts, 'Removed'))
    }
  }, [loading, parts])

  const refresh = () => id && getParts({ activityId: id })

  const onPlus = () => navigation.navigate('ActivitySpareParts', { ticketId, activityCode, activityId: id, direction: 'Installation' })

  const onMinus = () => navigation.navigate('ActivitySpareParts', { ticketId, activityCode, activityId: id, direction: 'Removed' })

  const onPress = (part: ActivitySparePart) =>
    navigation.navigate('ActivitySparePartsShoppingCart', {
      ticketId,
      activityCode,
      articleCode: part.articleCode,
      serialNumber: part.serialnumber,
      activityId: id,
      direction: part.direction,
    })

  return (
    <>
      <IM.Card
        spacing={spacing}
        head={<CardLeftTitle text={i18n.t('SPARE_PARTS')} />}
        style={styles.container}
        noContentSpacing
        buttons={{
          buttons:
            isSmallDevice || onlyRead
              ? undefined
              : [
                  {
                    icon: ['fal', 'plus'],
                    onPress: onPlus,
                    backGroundColor: theme.chip.installation,
                  },
                  {
                    icon: ['fal', 'minus'],
                    onPress: onMinus,
                    backGroundColor: theme.chip.removal,
                  },
                ],
        }}>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <IM.View style={{ margin: isSmallDevice || parts?.length === 0 ? 6 : 10 }}>
          {loading === false && parts !== undefined && parts.length !== 0 ? (
            <>
              {!isSmallDevice && (
                <IM.View>
                  <DynamicView style={[IMLayout.flex.f1, styles.headerContainer]}>
                    <CellText style={firstColumn} value={i18n.t('ARTICLE_CODE')} secondary numberOfLines={1} />
                    <CellText style={secondColumn} value={i18n.t('SERIAL_NUMBER')} secondary numberOfLines={1} />
                    <CellText style={thirdColumn} value={i18n.t('BILLING')} secondary numberOfLines={1} />
                    <CellText style={fourthColumn} value={i18n.t('DESCRIPTION')} secondary numberOfLines={1} />
                    <CellText style={fifthColum} value={i18n.t('QUANTITY')} secondary numberOfLines={1} />
                    {userSettings?.showPriceOnRegistrationOfSpareParts && (
                      <CellText style={sixthColum} value={i18n.t('PRICE')} secondary numberOfLines={1} />
                    )}
                    <CellText style={seventhColum} value={i18n.t('TYPE')} secondary numberOfLines={1} />
                  </DynamicView>
                  <Separator spacing="none" type="primary" />
                </IM.View>
              )}
              {parts?.map((data, index) => (
                <IM.View key={data.id}>
                  <Pressable disabled={onlyRead || !isSmallDevice} onPress={() => onPress(data)}>
                    <IM.View style={IMLayout.flex.row}>
                      <DynamicView style={IMLayout.flex.f1} flexLimitSmallDevice={1}>
                        <CellText title={i18n.t('ARTICLE_CODE')} value={<TextTable text={data.articleCode} />} disableSpacing style={firstColumn} />
                        <CellText title={i18n.t('SERIAL_NUMBER')} value={<TextTable text={data.serialnumber ?? '-'} />} style={secondColumn} />
                        <CellText title={i18n.t('BILLING')} value={<TextTable text={data.invoicingType ?? '-'} />} style={thirdColumn} />
                        <CellText
                          title={i18n.t('DESCRIPTION')}
                          value={<TextTable numberOfLines={3} text={data.description ?? '-'} />}
                          style={fourthColumn}
                        />
                        <CellText
                          title={i18n.t('QUANTITY')}
                          value={
                            <TextTable
                              text={
                                (data.quantity ? numberUtils.convertNumberToLocaleString(data.quantity, language) ?? '' : '') +
                                (data.measurementUnit ? ` ${data.measurementUnit}` : '')
                              }
                            />
                          }
                          style={fifthColum}
                        />
                        {userSettings?.showPriceOnRegistrationOfSpareParts && (
                          <CellText
                            title={i18n.t('PRICE')}
                            value={<TextTable text={data.price ? appUtils.formatPrice(data.price, language) + ' €' : '-'} />}
                            style={isSmallDevice ? IMLayout.flex.f1 : sixthColum}
                          />
                        )}
                        <CellText
                          title={i18n.t('TYPE')}
                          value={
                            <Chip
                              value={data.direction === 'Installation' ? i18n.t('INSTALLATION') : i18n.t('REMOVAL')}
                              color={data.direction === 'Installation' ? theme.chip.installation : theme.chip.removal}
                              textColor={IMStyle.palette.white}
                            />
                          }
                          // eslint-disable-next-line react-native/no-inline-styles
                          style={[seventhColum, { alignItems: 'center' }]}
                        />
                      </DynamicView>
                      {!onlyRead && isSmallDevice && <NavigationIcon enableTableStyle />}
                    </IM.View>
                  </Pressable>
                  {index !== parts?.length - 1 && <Separator spacing="none" />}
                </IM.View>
              ))}
            </>
          ) : (
            <>
              {parts === undefined ? (
                <IM.View spacing={isSmallDevice ? 'horizontal' : 'none'}>
                  {isSmallDevice ? (
                    <>
                      <SkeletonText width="80%" spacing="top" />
                      <SkeletonText width="100%" spacing="top" />
                      <SkeletonText width="100%" spacing="top" />
                      <SkeletonText width="100%" spacing="vertical" />
                    </>
                  ) : (
                    <>
                      <SkeletonText width="100%" spacing="top" />
                      <Separator spacing="bottom" />
                      <SkeletonText width="100%" spacing="bottom" />
                    </>
                  )}
                </IM.View>
              ) : (
                <IM.View spacing="all">
                  <IM.Text secondary>{i18n.t('NO_SPARE_PART_FOUND')}</IM.Text>
                </IM.View>
              )}
            </>
          )}
          {isSmallDevice && !onlyRead && (
            <>
              {!(loading === false && parts !== undefined && parts.length === 0) && <Separator spacing="none" />}
              <IM.View style={[IMLayout.flex.row, IMLayout.flex.f1, styles.buttonContainer]} spacing="top">
                <CardButton
                  title={i18n.t('REMOVE')}
                  onPress={onMinus}
                  style={styles.button}
                  disableSeparator={true}
                  color={theme.chip.removal}
                  disabled={!isOnline || loading !== false}
                />
                <CardButton title={i18n.t('INSTALL')} onPress={onPlus} style={styles.button} disableSeparator={true} disabled={loading !== false} />
              </IM.View>
            </>
          )}
        </IM.View>
      </IM.Card>
    </>
  )
})

export default ActivitySparePartsCard

const styles = StyleSheet.create({
  button: { margin: 6, marginTop: 3 },
  buttonContainer: { justifyContent: 'flex-end' },
  container: {
    flexGrow: 1,
  },
  headerContainer: { paddingHorizontal: 8, marginBottom: 2 },
})
