export const ACCESS_DATA_CARD_SELECTION_EVENT_KEY = 'AccessDataCardSelectionEvent'
export const ACCESS_DATA_FILTER_CHANGE_EVENT_KEY = 'AccessDataFilterChangeEvent'
export const ACTIVITY_CLOSE_ACT_ID_EVENT_KEY = 'ActivityActIdEvent'
export const ACTIVITY_CLOSE_NEXT_ACT_EVENT_KEY = 'ActivityCloseNextActEvent'
export const ACTIVITY_CLOSE_REPORT_EVENT_KEY = 'ActivityCloseReportEvent'
export const ACTIVITY_CLOSE_SIGNATURE_EVENT_KEY = 'ActivityCloseSignatureEvent'
export const BARCODE_EVENT_KEY = 'BarcodeEvent'
export const EDIT_ACCESS_DATA_EVENT_KEY = 'EditAccessDataEvent'
export const EDIT_ACTIVITY_ARTICLE_COUNTERS_EVENT_KEY = 'EditActivityArticleCounterEvent'
export const EDIT_ACTIVITY_PLANNING_TIME_EVENT_KEY = 'EditActivityPlanningTimeEvent'
export const EDIT_ACTIVITY_PURCHASE_ORDER_EVENT_KEY = 'EditActivityPurchaseOrderEvent'
export const EDIT_ACTIVITY_SPARE_PARTS_EVENT_KEY = 'EditActivitySparePartsEvent'
export const EDIT_ACTIVITY_SPARE_PARTS_SHOPPING_CART_EVENT_KEY = 'EditActivitySparePartsShoppingCartEvent'
export const EDIT_ACTIVITY_TIME_EVENT_KEY = 'EditActivityTimeEvent'
export const EDIT_ARTICLE_COUNTERS_EVENT_KEY = 'EditArticleCounterEvent'
export const EDIT_CONTACT_DATA_EVENT_KEY = 'EditContactDataEvent'
export const EDIT_DESTINATION_DATA_EVENT_KEY = 'EditDestinationDataEvent'
export const EDIT_NOTE_DATA_EVENT_KEY = 'EditNoteDataEvent'
export const HIDE_NAVIGATION_EVENT_KEY = 'HideNavigationEvent'
export const INFOBOX_FOLDER_EVENT_KEY = 'InfoboxFolderEvent'
export const INFOBOX_NEW_INFO_EVENT_KEY = 'InfoboxNewInfoEventKey'
export const PLANNER_CREATE_EVENT_KEY = 'PlannerCreateEventKey'
export const PLANNER_CREATE_TRAVEL_TIME_KEY = 'PlannerCreateTravelTimeKey'
export const PLANNER_NEXT_EVENT_KEY = 'PlannerNextEventKey'
export const PLANNER_PICK_EVENT_KEY = 'PlannerPickEventKey'
export const PLANNER_PREVIOUS_EVENT_KEY = 'PlannerPreviousEventKey'
export const REFRESH_ACTIVITY_HISTORY_LIST_EVENT_KEY = 'RefreshActivityHistoryListEvent'
export const REFRESH_ACTIVITY_PLANNING_LIST_EVENT_KEY = 'RefreshActivityPlanningListEvent'
export const REFRESH_ACTIVITY_QUALITY_CHECK_EVENT_KEY = 'RefreshActivityQualityCheckEvent'
export const REFRESH_ACTIVITY_VIEW_EVENT_KEY = 'RefreshActivityViewEvent'
export const REFRESH_ADD_FIELD_ACTIVITY_KEY = 'RefreshAddFieldActivityEvent'
export const REFRESH_ADD_FIELD_TICKET_KEY = 'RefreshAddFieldTicketEvent'
export const REFRESH_CLASSIFICATION_SERIAL_NUMBER_KEY = 'RefreshClassificationSerialNumberEvent'
export const REFRESH_CLASSIFICATION_TICKET_KEY = 'RefreshClassificationTicketEvent'
export const REFRESH_CONTACT_EVENT_KEY = 'RefreshContactEvent'
export const REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY = 'RefreshInfoboxButtonCounterEvent'
export const REFRESH_INFOBOX_EVENT_KEY = 'RefreshInfoboxEvent'
export const REFRESH_OTHER_TIME_MANAGER_EVENT_KEY = 'RefreshOtherTimeManagerEvent'
export const REFRESH_SERIAL_NUMBER_VIEW_EVENT_KEY = 'RefreshSerialNumberEvent'
export const REFRESH_TICKET_ACTIVITY_EVENT_KEY = 'RefreshTicketActivityEvent'
export const REFRESH_TICKET_ARTICLE_EVENT_KEY = 'RefreshTicketArticleEvent'
export const REFRESH_TICKET_DETAIL_EVENT_KEY = 'RefreshTicketDetailEvent'
export const REFRESH_TICKET_LIST_EVENT_KEY = 'RefreshTicketListEvent'
export const REFRESH_TICKET_LIST_TIMES_EVENT_KEY = 'RefreshTicketListTimesEvent'
export const REFRESH_TRAVEL_TIME_BUTTON_EVENT_KEY = 'RefreshTravelTimeButtonEvent'
export const REMOVE_ACTIVITY_PLANNING_LIST_EVENT_KEY = 'RemoveActivityPlanningListEvent'
export const SECTION_LIST_CLOSE_TO_END_EVENT_KEY = 'SectionListCloseToEndEvent'
export const SELECT_GROUP_EVENT_KEY = 'SelectGroupEvent'
export const SELECT_SN_EVENT_KEY = 'SelectSerialNumberEvent'
export const TEXT_INPUT_BLUR_EVENT_KEY = 'TextInputBlurEvent'
export const TEXT_INPUT_POP_UP_CLOSE_EVENT_KEY = 'TextInputPopUpCloseEvent'
