import { IM, IMLayout, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import { ThemeColorExpanded } from '../../types'

export type ExtraActivity = {
  name: string
}

export type ExtraActivityListCardProps = {
  activity: ExtraActivity
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

const ExtraActivityListCard = memo(function ExtraActivityListCard({ activity, spacing, style }: ExtraActivityListCardProps) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.Card
      head={<CardLeftTitle color={theme.header.detail.background} />}
      borderless={false}
      spacing={spacing}
      style={[style, { borderRadius: IMLayout.borderRadius, backgroundColor: theme.card.background }]}>
      <IM.Text>{activity.name}</IM.Text>
    </IM.Card>
  )
})

export default ExtraActivityListCard
