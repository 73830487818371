import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CalendarViewMode } from '@howljs/calendar-kit'
import { IMStyle, useDimensions, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { useEffect } from 'react'
import { Platform } from 'react-native'
import { useRecoilState } from 'recoil'

import { ThemeColorExpanded } from '../../types'
import CalendarUtils from '../../utils/calendarUtils'
import { calendarViewAtom } from '../../utils/stateManager'
import PressableIcon, { PressableIconProps } from '../Infominds/PressableIcon'
import TabSelector from '../TabSelector'
import useScreen from './hooks/useScreen'
import { CALENDAR_ICON, LIST_ICON, MAP_ICON, TicketScreenViewType } from './types'

const calendarViews: CalendarViewMode[] = ['day', 'threeDays', 'workWeek', 'week']

export type TicketHeaderIconType = { type: TicketScreenViewType; icon: IconProp; size: number }

type Props = {
  types: TicketHeaderIconType[]
}

export default function HeaderTicketChangeViewIcon({ types, ...props }: Omit<PressableIconProps, 'icon'> & Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isSmallDevice } = useDimensions()
  const { ticketView, setTicketView } = useScreen()
  const { sessionKey } = useAuthentication()

  const [viewMode, setViewMode] = useRecoilState(calendarViewAtom(sessionKey))

  useEffect(() => {
    Platform.OS === 'web' && ticketView === TicketScreenViewType.LOCATION && isSmallDevice && setTicketView(TicketScreenViewType.CALENDAR)
  }, [ticketView, isSmallDevice])

  const currentViewIndex = Math.max(
    types.findIndex(t => t.type === ticketView),
    0
  )
  const currentView = types.find(t => t.type === ticketView) ?? types[0]
  const nextViewIndex = currentViewIndex >= types.length - 1 ? 0 : currentViewIndex + 1

  const currentCalendarView = calendarViews.findIndex(t => t === viewMode)
  const nextCalendarViewIndex = currentCalendarView >= calendarViews.length - 1 ? 0 : currentCalendarView + 1

  if (!currentView) return <></>

  return (
    <>
      {currentView.type === TicketScreenViewType.CALENDAR && (
        <PressableIcon
          color={IMStyle.palette.white}
          icon={CalendarUtils.getIcon(calendarViews[nextCalendarViewIndex])}
          size={currentView.size}
          onPress={() => setViewMode(calendarViews[nextCalendarViewIndex])}
          hitSlop={{ top: 10, bottom: 8 }}
          {...props}
        />
      )}
      {Platform.OS !== 'web' || isSmallDevice ? (
        <PressableIcon
          color={IMStyle.palette.white}
          icon={currentView.icon}
          size={currentView.size}
          onPress={() => setTicketView(types[nextViewIndex].type)}
          hitSlop={{ top: 10, bottom: 8 }}
          {...props}
        />
      ) : (
        <TabSelector selectionColor={theme.drawerNavigator.bar} height={30} width={360}>
          <TabSelector.Item
            icon={LIST_ICON.icon}
            iconSize={LIST_ICON.size}
            text={i18n.t('LIST')}
            selected={ticketView === TicketScreenViewType.LIST}
            textColor="white"
            selectedTextColor={theme.general.info}
            onPress={() => setTicketView(TicketScreenViewType.LIST)}
            backgroundColor={theme.drawerNavigator.bar}
            dividerColor={theme.drawerNavigator.background}
          />
          <TabSelector.Item
            icon={MAP_ICON.icon}
            iconSize={MAP_ICON.size}
            text={i18n.t('MAP')}
            selected={ticketView === TicketScreenViewType.LOCATION}
            onPress={() => setTicketView(TicketScreenViewType.LOCATION)}
            textColor="white"
            selectedTextColor={theme.general.info}
            backgroundColor={theme.drawerNavigator.bar}
            dividerColor={theme.drawerNavigator.background}
          />
          <TabSelector.Item
            icon={CALENDAR_ICON.icon}
            iconSize={CALENDAR_ICON.size}
            text={i18n.t('CALENDAR')}
            selected={ticketView === TicketScreenViewType.CALENDAR}
            onPress={() => setTicketView(TicketScreenViewType.CALENDAR)}
            textColor="white"
            selectedTextColor={theme.general.info}
            disableDivider
            backgroundColor={theme.drawerNavigator.bar}
          />
        </TabSelector>
      )}
    </>
  )
}
