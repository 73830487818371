import { useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'

import FilterDetailHeaderContainer from '../../components/screen/headers/containers/FilterDetailHeaderContainer'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import HeaderWithIcons from '../../components/screen/headers/HeaderWithIcons'
import QualityCheckFilterDetailHeader from '../../components/screen/headers/QualityCheckFilterDetailHeader'
import { Screen } from '../../components/screen/Screen'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import useQualityCheck from '../../hooks/useQualityCheck'
import useQualityCheckFilter from '../../hooks/useQualityCheckFilter'
import { CommonStackParamList } from '../../navigation/types'
import { CharacteristicsModified, LoadingType } from '../../types'
import QualityCheckView from './QualityCheckView'

interface Props {
  activityCode?: string
  serialNumber?: string
  serialNumberManufacturer?: string
  loading: LoadingType
  characteristics: CharacteristicsModified[]
  onRefresh: () => void
}

export default function QualityCheckViewWrapper({ activityCode, serialNumber, serialNumberManufacturer, ...props }: Props) {
  const { i18n } = useLanguage()
  const { enableDone } = useQualityCheck()
  const { groups, orders } = useQualityCheckFilter()
  const navigation = useNavigation<NavigationProp<CommonStackParamList>>()

  const activeGroups = groups.filter(el => el.active)
  const activeOrder = orders.filter(el => el.active)

  const { setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
  })

  useEffect(() => {
    setStatus(enableDone ? 'waiting' : 'done')
  }, [enableDone])

  const handleSDNavigation = () => {
    navigation.navigate('QualityCheckFilters')
  }

  return (
    <Screen>
      <Screen.Header goBack={handleGoBack}>
        <HeaderWithIcons
          enabledOffline
          title={i18n.t('QUALITY_CHECK')}
          description={activityCode ?? (serialNumber ?? '') + (serialNumberManufacturer ? ` (${serialNumberManufacturer})` : '')}
          tabletSearchComponent={<SearchDetailHeaderContainer enabledOffline dividers={['right']} enableQR />}
          tabletFilterComponent={
            <FilterDetailHeaderContainer dividers={['right', 'left']}>
              <QualityCheckFilterDetailHeader onSDNavigation={handleSDNavigation} />
            </FilterDetailHeaderContainer>
          }
          highlightFilter={activeGroups?.length !== 0 || activeOrder?.length !== 0}
          sync
        />
      </Screen.Header>
      <Screen.DetailHeader>
        <SearchDetailHeaderContainer enableQR />
        <FilterDetailHeaderContainer>
          <QualityCheckFilterDetailHeader onSDNavigation={handleSDNavigation} />
        </FilterDetailHeaderContainer>
      </Screen.DetailHeader>
      <Screen.Content>
        <QualityCheckView {...props} />
      </Screen.Content>
    </Screen>
  )
}
