import { IM, IMStyle, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilValue } from 'recoil'

import { PLANNER_NEXT_EVENT_KEY, PLANNER_PICK_EVENT_KEY, PLANNER_PREVIOUS_EVENT_KEY } from '../../constants/EmitterKeys'
import { ThemeColorExpanded } from '../../types'
import { plannerViewAtom } from '../../utils/stateManager'
import DateTimePicker from '../Infominds/DateTimePicker'
import Pressable from '../Infominds/Pressable'
import PressableIcon from '../Infominds/PressableIcon'
import PressableTextIcon from '../Infominds/PressableTextIcon'
import Text from '../Text'

const PlannerHeader = () => {
  const { i18n } = useLanguage()
  const { sessionKey } = useAuthentication()
  const { theme } = useTheme<ThemeColorExpanded>()
  const plannerView = useRecoilValue(plannerViewAtom(sessionKey))

  const [showPicker, setShowPicker] = useState(false)

  const { emit: onNext } = useEvent({ key: PLANNER_NEXT_EVENT_KEY })
  const { emit: onPrevious } = useEvent({ key: PLANNER_PREVIOUS_EVENT_KEY })
  const { emit: onPick } = useEvent<Date>({ key: PLANNER_PICK_EVENT_KEY })

  return (
    <>
      <IM.View style={styles.container}>
        <IM.View style={styles.leftContainer}>
          <PressableTextIcon
            icon={['fal', 'calendar-day']}
            onPress={() => onPick(new Date())}
            color={theme.header.main.text.primary}
            spacing="right"
            iconSize={18}
            style={{ fontSize: IMStyle.typography.fontSizeRegular }}>
            {i18n.t('CALENDAR_TODAY')}
          </PressableTextIcon>
          <PressableIcon icon={['fal', 'chevron-left']} size={20} color={theme.header.main.text.primary} onPress={() => onPrevious()} />
          <PressableIcon icon={['fal', 'chevron-right']} size={20} color={theme.header.main.text.primary} onPress={() => onNext()} />
          <Pressable spacing="left" onPress={() => setShowPicker(true)}>
            <Text style={[styles.date, { color: theme.header.main.text.primary }]}>{plannerView?.label}</Text>
          </Pressable>
        </IM.View>
        <IM.View style={styles.rightContainer}>
          {/* {__DEV__ && (
            <Pressable
              onPress={() =>
                !embed
                  ? navigation.navigate('PlannerEmbed', { language: 'de', code: 1, companyId: undefined, objectId: undefined })
                  : navigation.navigate('Planner')
              }>
              <IM.Text style={{ color: theme.header.main.text.primary }}>{embed ? 'NOR' : 'EMB'}</IM.Text>
            </Pressable>
          )} */}
          {/* <ChartLegend sections={legendElements} disabled={loadingActivityTypes !== false || loadingActivityTypes !== false} /> */}

          {/* {embed && (
            <PressableIcon
              icon={['fal', 'gear']}
              size={20}
              color={theme.header.main.text.primary}
              onPress={() => controller.show({ showPlannerSettings: true })}
              // eslint-disable-next-line react-native/no-inline-styles
              style={{ marginHorizontal: 4 }}
            />
          )} */}
        </IM.View>
      </IM.View>
      <DateTimePicker
        key={plannerView?.date.toString() ?? ''}
        date={plannerView ? new Date(plannerView.date) : new Date()}
        setDate={date => {
          onPick(date)
          setShowPicker(false)
        }}
        show={showPicker}
        setShow={setShowPicker}
        mode="date"
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: 'row', alignItems: 'center' },
  rightContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  leftContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  date: { fontSize: 16 },
})

export default PlannerHeader
