import { useEvent, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import api from '../../apis/apiCalls'
import { Activity, Ticket } from '../../apis/types/apiResponseTypes'
import useRequest from '../../components/Infominds/hooks/useRequest'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import HeaderWithIcons from '../../components/screen/headers/HeaderWithIcons'
import { Screen } from '../../components/screen/Screen'
import { REFRESH_ACTIVITY_VIEW_EVENT_KEY, REFRESH_TICKET_DETAIL_EVENT_KEY } from '../../constants/EmitterKeys'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { CommonStackScreenProps } from '../../navigation/types'
import { EditInvoiceTypeParams, ModalScreenProps, ModalScreenRef } from '../../types'
import EditInvoiceTypeView from '../../views/common/EditInvoiceTypeView'

function EditInvoiceTypeScreen(
  { route, controller }: Omit<CommonStackScreenProps<'EditInvoiceType'>, 'navigation'> & ModalScreenProps<EditInvoiceTypeParams>,
  ref: ForwardedRef<ModalScreenRef>
) {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const [goBackLoading, setGoBackLoading] = useState(false)
  const [uploadStarted, setUploadStarted] = useState(false)

  const { emit: refreshTicket } = useEvent({ key: REFRESH_TICKET_DETAIL_EVENT_KEY })
  const { emit: refreshActivity } = useEvent({ key: REFRESH_ACTIVITY_VIEW_EVENT_KEY })

  const { handleGoBack } = useEditOrCreateScreenBackManager({
    title: '',
    description: '',
    controller,
  })

  const { request: requestTicket, loading: loadingTicket } = useRequest(api.editTicket)
  const { request: requestActivity, loading: loadingActivity } = useRequest(api.editActivity)

  useEffect(() => {
    const loading = route.params.type === 'Activity' ? loadingActivity : loadingTicket

    if (uploadStarted && loading === false) {
      setUploadStarted(false)
      refreshActivity()
      refreshTicket()
      handleGoBack()
    }

    if (uploadStarted && (loading === 'catched' || loading === 'aborted')) {
      setUploadStarted(false)
      setGoBackLoading(false)
    }
  }, [uploadStarted, loadingActivity, loadingTicket])

  const isModal = controller !== undefined

  return (
    <SearchProvider>
      <Screen transparent={isModal}>
        <Screen.Header goBack={handleGoBack} backHandlerCallback={handleGoBack} isModal={isModal} goBackLoading={goBackLoading}>
          <HeaderWithIcons
            title={i18n.t(route.params.type === 'Activity' ? 'ACTIVITY_INVOICE_TYPE' : 'TICKET_INVOICE_TYPE')}
            disableFilter
            tabletSearchComponent={<SearchDetailHeaderContainer iconPosition="right" />}
          />
        </Screen.Header>
        <Screen.DetailHeader>
          <SearchDetailHeaderContainer />
        </Screen.DetailHeader>
        <Screen.Content>
          <EditInvoiceTypeView
            invoiceTypeId={route.params.invoiceTypeId}
            onChange={newInvoiceTypeId => {
              setUploadStarted(true)
              setGoBackLoading(true)

              route.params.type === 'Ticket'
                ? requestTicket({
                    id: route.params.id,
                    invoiceTypeId: newInvoiceTypeId,
                  } as unknown as Ticket)
                : requestActivity({
                    id: route.params.id,
                    invoiceTypeId: newInvoiceTypeId,
                  } as unknown as Activity)
            }}
          />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default forwardRef(EditInvoiceTypeScreen)
