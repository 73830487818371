import { useEvent } from '@infominds/react-native-components'
import dayjs from 'dayjs'
import { useCallback, useEffect } from 'react'
import { EventItem } from 'react-big-scheduler-stch'

import api from '../apis/apiCalls'
import { PlanningTime, TravelTime } from '../apis/types/apiResponseTypes'
import useRequest from '../components/Infominds/hooks/useRequest'
import { PlannerViewType } from '../components/planner/types'
import {
  PLANNER_CREATE_EVENT_KEY,
  PLANNER_CREATE_TRAVEL_TIME_KEY,
  REFRESH_ACTIVITY_PLANNING_LIST_EVENT_KEY,
  REMOVE_ACTIVITY_PLANNING_LIST_EVENT_KEY,
} from '../constants/EmitterKeys'
import TimeUtils from '../utils/TimeUtils'
import usePlanner from './usePlanner'

export default function usePlannerFunctions() {
  const { reloadEvents } = usePlanner()

  const { emit: refreshActivityList } = useEvent({ key: REFRESH_ACTIVITY_PLANNING_LIST_EVENT_KEY })
  const { emit: removeActivityFromList } = useEvent<string>({ key: REMOVE_ACTIVITY_PLANNING_LIST_EVENT_KEY })
  const { emit: addPlanningTime } = useEvent<PlanningTime>({ key: PLANNER_CREATE_EVENT_KEY })
  const { emit: addTravelTime } = useEvent<TravelTime>({ key: PLANNER_CREATE_TRAVEL_TIME_KEY })

  const { request: edit, error: errorEdit } = useRequest(api.editPlanningTime)
  const { request: deleteTime, error: errorDelete } = useRequest(api.deletePlanningTime, {
    onResult: () => {
      refreshActivityList()
    },
  })
  const { request: createTravelTime, error: errorCreateTravelTime } = useRequest(api.startTravelTime, {
    onResult: result => {
      addTravelTime(result as TravelTime)
    },
  })

  const { request: create, error: errorCreate } = useRequest(api.createPlanningTime, {
    onResult: result => {
      addPlanningTime(result as PlanningTime)
    },
  })

  useEffect(() => {
    if (!!errorCreate || !!errorCreateTravelTime || !!errorEdit || !!errorDelete) {
      reloadEvents()

      if (errorCreate !== '') {
        refreshActivityList()
      }
    }
  }, [errorCreate, errorCreateTravelTime, errorEdit, errorDelete])

  const onNewEvent = useCallback(
    (id: string, ticketId: string, slotId: string, start: string, end: string, view: PlannerViewType, defaultStart: number, defaultTo: number) => {
      create({
        ticketId: ticketId,
        activityId: id,
        employeeId: slotId,
        modifyActivity: true,
        ...prepareApiPayload(start, end),
        ...(view === 'month' || view === 'week'
          ? {
              from: defaultStart,
              until: defaultTo,
            }
          : {}),
      })
      removeActivityFromList(id.toString())
    },
    []
  )

  const onNewTravelTime = useCallback(
    (slotId: string, start: string, end: string, view: PlannerViewType, defaultStart: number, defaultTo: number) => {
      return createTravelTime({
        employeeId: slotId,
        ...prepareApiPayload(start, end),
        ...(view === 'month' || view === 'week'
          ? {
              from: defaultStart,
              until: defaultTo,
            }
          : {}),
      })
    },
    []
  )

  const onMove = useCallback((event: EventItem, slotId: string, _slotName: string, start: string, end: string, _type?: string) => {
    edit({
      id: event.id as string,
      employeeId: slotId,
      modifyActivity: true,
      ...prepareApiPayload(start, end),
    })
  }, [])

  const onMoveStart = useCallback((event: EventItem, start: string) => {
    const result = prepareApiPayload(start, dayjs(event.end).format('YYYY-MM-DD HH:mm:ss'))

    edit({
      id: event.id as string,
      employeeId: event.resourceId,
      date: result.date,
      from: result.from,
      until: result.until,
      modifyActivity: true,
    })
  }, [])

  const onMoveEnd = useCallback((event: EventItem, end: string) => {
    const result = prepareApiPayload(dayjs(event.start).format('YYYY-MM-DD HH:mm:ss'), end)

    edit({
      id: event.id as string,
      employeeId: event.resourceId,
      date: result.date,
      from: result.from,
      until: result.until,
      modifyActivity: true,
    })
  }, [])

  const onRemove = useCallback((event: EventItem) => {
    deleteTime({
      id: event.id as string,
      modifyActivity: true,
    })
  }, [])

  return { onMove, onMoveStart, onMoveEnd, onNewEvent, onNewTravelTime, onRemove }
}

const prepareApiPayload = (start: string, end: string): { date: string; from: number; until: number } => {
  const startDate = start.split(' ')[0]
  const timeStart = start.split(' ')[1]
  const endDate = end.split(' ')[0]
  const timeEnd = end.split(' ')[1]

  const days = dayjs(endDate).diff(dayjs(startDate), 'day')

  return {
    date: startDate + 'T00:00:00.000Z',
    from: TimeUtils.timeToSeconds(timeStart),
    until: TimeUtils.timeToSeconds(timeEnd) + days * 24 * 60 * 60,
  }
}
