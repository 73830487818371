/* eslint-disable @typescript-eslint/no-unsafe-call */

import { ModalController, useAlert, useDimensions, useLanguage } from '@infominds/react-native-components'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { UploadStatus } from '../types'
import navigationUtils from '../utils/navigationUtils'
import { useBeforeUnload } from './useBeforeUnload'
import usePopStateModal from './usePopStateModal'

interface Props<T> {
  title: string
  description: string
  controller?: ModalController<T>
  onDiscard?: () => void
}

export default function useEditOrCreateScreenBackManager<T>({ title, description, controller, onDiscard }: Props<T>) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const { isSmallDevice } = useDimensions()

  const prevStatus = useRef<UploadStatus>('done')
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('done')

  const unsavedChanges = useMemo(() => uploadStatus === 'waiting' || uploadStatus === 'mandatoryMissing', [uploadStatus])

  useBeforeUnload(unsavedChanges)
  usePopStateModal<T>(unsavedChanges, title, description, controller)

  useEffect(() => {
    if (prevStatus.current === 'uploading' && uploadStatus === 'done') handleGoBack()
  }, [prevStatus, uploadStatus])

  useFocusEffect(
    useCallback(() => {
      const unsubscribe = navigation
        .getParent()
        ?.getParent()
        // @ts-ignore don't know type
        ?.addListener('tabPress', (e: unknown) => handleTabPress(e, unsavedChanges))

      return unsubscribe
    }, [navigation, unsavedChanges])
  )

  useFocusEffect(
    useCallback(() => {
      const unsubscribe = navigation
        .getParent()
        // @ts-ignore don't know type
        ?.addListener('tabPress', (e: unknown) => handleTabPress(e, unsavedChanges))

      return unsubscribe
    }, [navigation, unsavedChanges])
  )

  const handleTabPress = (e: unknown, unsaved: boolean) => {
    if (unsaved) {
      // @ts-ignore due to previous error
      e.preventDefault()
      showAlert(() => {
        // @ts-ignore todo
        navigation.popToTop()
      })
    }
  }

  const handleGoBack = useCallback(() => {
    if (unsavedChanges) {
      showAlert(() => {
        onDiscard?.()
        navigationUtils.navigationBack(
          navigation,
          isSmallDevice,
          controller === undefined
            ? undefined
            : () => {
                controller?.close()
              }
        )
      })
    } else {
      navigationUtils.navigationBack(
        navigation,
        isSmallDevice,
        controller === undefined
          ? undefined
          : () => {
              controller?.close()
            }
      )
    }
  }, [unsavedChanges, isSmallDevice])

  const handleShowEditedAlert = useCallback(
    (onContinue: (discarded: boolean) => void) => {
      if (unsavedChanges) {
        showAlert(() => onContinue(true))
      } else {
        onContinue(false)
      }
    },
    [unsavedChanges]
  )

  const handleForceClose = useCallback(() => {
    navigationUtils.navigationBack(
      navigation,
      isSmallDevice,
      controller === undefined
        ? undefined
        : () => {
            controller?.close()
          }
    )
  }, [isSmallDevice])

  const handleChangeStatus = useCallback(
    (newStatus: UploadStatus) => {
      prevStatus.current = uploadStatus
      setUploadStatus(newStatus)
    },
    [prevStatus, uploadStatus]
  )

  const showAlert = (onDiscardCallback: () => void) =>
    alert.alert(title, description, [
      {
        text: i18n.t('DISCARD'),
        onPress: () => onDiscardCallback(),
        style: 'destructive',
      },
      {
        text: i18n.t('CANCEL'),
        onPress: () => {
          return
        },
        style: 'cancel',
      },
    ])

  return {
    status: uploadStatus,
    handleGoBack,
    handleForceClose,
    handleShowEditedAlert,
    setStatus: handleChangeStatus,
  }
}
