import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import dayjs from 'dayjs'
import React, { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { DATE_FORMAT, EventItem, Resource, SchedulerData, ViewType } from 'react-big-scheduler-stch'
import { Platform } from 'react-native'

import api from '../../apis/apiCalls'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_ACTIVITY_PLANNING_TIME, REQUEST_EMPLOYEE } from '../../constants/Keys'
import useUserSettings from '../../hooks/useUserSettings'
import { plannerUtils } from '../../utils/plannerUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import { PlannerView, PlannerViewType } from './types'

export type Props = {
  children: React.ReactNode
  resourceLabel: string
  plannerView: PlannerView | undefined
}

export type ContextProps = {
  schedulerData: SchedulerData
  view: PlannerViewType
  events: EventItem[]
  resources: Resource[]
  reloadEvents: () => void
}

export const PlannerContext = createContext<ContextProps | undefined>(undefined)

export function PlannerProvider({ children, resourceLabel, plannerView }: PropsWithChildren<Props>) {
  const { language } = useLanguage()
  const { colorScheme } = useTheme()
  const { userSettings } = useUserSettings()

  const [schedulerData, setSchedulerDate] = useState<SchedulerData>()

  const { item: employee, loadItem: getEmployee, loading: loadingEmployee } = useControlledLoader(api.getEmployee, { id: REQUEST_EMPLOYEE })
  const { item: times, loadItem: loadTimes } = useControlledLoader(api.getPlanningTime, { id: REQUEST_ACTIVITY_PLANNING_TIME })

  useEffect(() => {
    if (Platform.OS !== 'web') return

    getEmployee({})
  }, [])

  useEffect(() => {
    if (!plannerView) return

    load(plannerView)
  }, [plannerView])

  useEffect(() => {
    if (loadingEmployee !== false || userSettings === undefined) return

    const colors = CONSTANTS.planner.COLORS[colorScheme]

    document.body.style.setProperty('--gantt-background-color', colors.background.planner)
    document.body.style.setProperty('--text-color', colors.text)
    document.body.style.setProperty('--gantt-table-border', colors.border.primary)
    document.body.style.setProperty('--gantt-table-border-2', colors.border.secondary)

    const newScheduler = new SchedulerData(
      plannerView ? dayjs(plannerView.date).format(DATE_FORMAT) : dayjs().format(DATE_FORMAT),
      plannerView === undefined ? ViewType.Week : plannerUtils.convertToSchedulerViewType(plannerView.view),
      false,
      false,
      {
        dayMaxEvents: 99,
        weekMaxEvents: 9669,
        monthMaxEvents: 9669,
        quarterMaxEvents: 6599,
        yearMaxEvents: 9956,
        customMaxEvents: 9965,
        responsiveByParent: true,
        headerEnabled: false,
        resourceName: resourceLabel,
        besidesWidth: 0,
        nonWorkingTimeBodyBgColor: colors.nonWorking.background,
        nonWorkingTimeHeadBgColor: colors.nonWorking.background,
        nonWorkingTimeHeadColor: colors.nonWorking.text,
        minuteStep: 30,
        dayResourceTableWidth: 170,
        weekResourceTableWidth: 170,
        monthResourceTableWidth: 170,
        dayCellWidth: 52,
        weekCellWidth: `${CONSTANTS.planner.WEEK_CELL_WIDTH}%`,
        monthCellWidth: 150,
        eventItemHeight: CONSTANTS.planner.ITEM_HEIGHT,
        eventItemLineHeight: CONSTANTS.planner.ITEM_HEIGHT,
        schedulerMaxHeight: 0,
        tableHeaderHeight: CONSTANTS.planner.HEADER_HEIGHT,
        nonAgendaDayCellHeaderFormat: 'HH:mm',
        nonAgendaOtherCellHeaderFormat: `ddd DD${language === 'de' ? '.' : '/'}MM`,
        selectedAreaColor: colors.selection,
        eventItemPopoverPlacement: 'bottomLeftMousePosition',
        eventItemPopoverColor: colorScheme === 'dark' ? colors.background.popover : 'white',
        eventItemPopoverTrigger: 'hover',
        resizeDelay: 200,
      },
      {
        isNonWorkingTimeFunc: (date, time) =>
          plannerUtils.isNonWorkingTimeFunc(
            date,
            time,
            userSettings.planningCalendarWorkTimeFrom * 60,
            userSettings.planningCalendarWorkTimeUntil * 60,
            userSettings.planningCalendarShowSaturdayAsWorkDay
          ),
      }
    )

    newScheduler.setSchedulerLocale(plannerUtils.getDayjsLocale(language))
    newScheduler.setResources(plannerUtils.createResources(employee))

    setSchedulerDate(newScheduler)
  }, [loadingEmployee, employee, language, colorScheme, resourceLabel])

  const load = (pView: PlannerView) => {
    if (Platform.OS !== 'web') return

    const start = plannerUtils.toUTC(pView.date, language, { start: pView.view })
    const end = plannerUtils.toUTC(pView.date, language, { end: pView.view })

    loadTimes({ dateFrom: start, dateUntil: end })
  }

  const resources = useMemo(() => plannerUtils.createResources(employee), [employee])

  const props = useMemo<ContextProps>(() => {
    const view = plannerView?.view ?? 'week'
    const events = plannerUtils.createEventsFromPlanningTimes(times, view)

    return {
      schedulerData: schedulerData as SchedulerData,
      view,
      events,
      resources,
      reloadEvents: () => {
        if (!plannerView) return
        load(plannerView)
      },
    }
  }, [schedulerData, resources, plannerView, times])

  return (
    <PlannerContext.Provider value={props}>{props.schedulerData === undefined ? <IM.LoadingSpinner isVisible /> : children}</PlannerContext.Provider>
  )
}
